<template>
  <v-row justify="space-between">
    <v-col cols="auto" class="vpd-summary__container py-5">
      <div class="subtitle-1">{{ title }}</div>
    </v-col>
    <v-col cols="auto">
      <v-col cols="auto" class="vpd-summary__amounts">
        <span
          v-for="sum in summaries"
          :key="sum.currency"
          class="vpd-summary__value title"
        >
          {{ getNiceValue(sum.amount, sum.currency) }}
        </span>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import "./_vpd-summary.scss";
import { getFormattedAmountValue } from "../../modules/helpers";
export default {
  name: "vpd-summary",
  props: {
    summaries: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: false,
    },
  },
  methods: {
    getNiceValue(value, currency) {
      return getFormattedAmountValue(value, currency);
    },
  },
};
</script>
