import axiosService from "@/modules/axios-service";

class BankAccountConsentsService {
  path = "/accounts";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async renew(uuid, requestBody) {
    const path = this.path +
    "/" +
    uuid +
    "/consents"
    return await axiosService.post(path, requestBody);
  }

  async delete(uuid) {
    const path = this.path +
    "/" +
    uuid +
    "/consents"
    return await axiosService.delete(path);
  }

}

export default new BankAccountConsentsService();