<template>
  <v-card class="vpd-panel vpd-change-password-panel">
    <vpd-card-title
      v-if="showForm"
      title="Jelszó megváltoztatása"
      has-back-button
    ></vpd-card-title>
    <vpd-card-title
      v-else
      title="A jelszó megváltoztatása sikeres"
    ></vpd-card-title>
    <v-divider></v-divider>
    <v-alert
      v-model="alert"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertMessage }}
    </v-alert>
    <v-form v-if="showForm" ref="form" v-model="valid">
      <v-row justify="center">
        <v-col align="center" class="pt-1 pb-2">
          <v-text-field
            label="Jelenlegi jelszó"
            type="password"
            v-model="oldPassword"
            dense
            height="50"
            hint="Min.8 karakter"
            :rules="passwordRules"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-1 pb-2">
          <v-text-field
            label="Új jelszó"
            type="password"
            v-model="newPassword"
            dense
            height="50"
            hint="Min.8 karakter"
            :rules="passwordRules"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-1 pb-2">
          <v-text-field
            type="password"
            label="Jelszó mégegyszer"
            v-model="newPasswordRe"
            :rules="matchRules"
            outlined
            dense
            height="50"
            ref="passcheck"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <v-btn
            class="primary huge fix-width"
            @submit.prevent
            type="submit"
            @click.prevent="submit()"
            >Jelszó megváltoztatása</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <div v-if="showBackButton">
      <v-row justify="center">
        <v-col align="center">
          <v-btn class="primary huge fix-width" @click.prevent="routeToHome()"
            >Vissza a Főoldalra</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
export default {
  name: "vpd-change-password-panel",
  data: () => ({
    alert: false,
    alertMessage: "",
    alertType: "error",
    valid: false,
    overlay: true,
    username: null,
    userId: null,
    email: null,
    newPasswordRe: null,
    dateCreated: null,
    newPassword: null,
    oldPassword: null,
    profilePicture: false,
    showChangePwForm: false,
    showForm: true,
  }),
  computed: {
    matchRules() {
      const context = this;
      return [
        function (v) {
          if (context.newPassword == null) return;
          return (v && v === context.newPassword) || "A jelszavak nem egyeznek";
        },
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || "Hiányzó jelszó",
        (v) => (v && v.length >= 8) || "A jelszó min. 8 karakter",
        (v) =>
          /[a-zaáeéiíoóöőuúüű]/.test(v) ||
          "A jelszónak min. 1 kisbetűt kell tartalmaznia",
        (v) =>
          /[A-ZAÁEÉIÍOÓÖŐUÚÜŰ]/.test(v) ||
          "A jelszónak min. 1 nagybetűt kell tartalmaznia",
        (v) =>
          /[0-9]+/.test(v) || "A jelszónak min. 1 számot kell tartalmaznia",
      ];
    },
  },
  components: { VpdCardTitle },
  methods: {
    routeToHome() {
      return this.$router.push("/");
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.oldPassword, this.newPassword);
      }
    },
  },
};
</script>

<style scoped></style>
