import state from "./_state";
import actions from "./_actions";
import mutations from "./_mutations";
import getters from "./_getters";
import machine from "./_machine";

export default {
  namespaced: true,
  state,
  machine,
  getters,
  actions,
  mutations,
};
