<template>
  <v-container class="vpd-service-panels v-sub-content">
    <template>
      <v-expansion-panels accordion>
        <v-expansion-panel
            v-for="(service, serviceIndex) in services"
            :key="service.id"
            v-model="state"
            class="vpd-service-panel"
        >
          <v-expansion-panel-header class="vpd-expand-header pa-0">
            <v-row align="center" class="vpd-service-panel__row">
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  class="d-flex space-between py-0 vpd-service-panel__col"
              >
                <img :src="getServiceImgUrl(service)"/>
                <div class="ps-3">
                  <div class="subtitle-1">
                    {{ service.name }}
                  </div>
                  <div class="caption">
                    <div class="mb-0">
                      <vpd-markdown :source="service.shortDescription"/>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  class="d-flex flex-column align-left py-0"
              >
                <div v-if="service.hasActiveAccount" class="pl-15 pl-lg-0">
                  <a
                      href="#"
                      @click="
                      connectAction(service.numberOfConnectedAccounts).action()
                    "
                  >{{
                      connectAction(service.numberOfConnectedAccounts).text
                    }}</a
                  >
                </div>
                <div v-if="service.hasActiveAccount" class="pl-15 pl-lg-0">
                  <span
                      v-if="!service.numberOfConnectedAccounts"
                      key="account-expiration-state"
                  />
                  <span
                      v-else-if="moment().isBefore(service.nearestExpirationDate)"
                      key="account-expiration-state"
                      :class="isWarned(service.nearestExpirationDate)"
                  >
                    Ebből egy számla
                    {{ since(service.nearestExpirationDate) }} lejár</span
                  >
                  <span
                      v-else
                      key="account-expiration-state"
                      :class="isWarned(service.nearestExpirationDate)"
                  >Ebből egy számla
                    {{ since(service.nearestExpirationDate) }} lejárt</span
                  >
                </div>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  class="d-flex align-center text-xs-left text-lg-right pr-lg-8 pb-0"
              >
                <v-spacer class="d-none d-lg-flex"/>
                <span class="subtitle-1 py-0 font-weight-bolt padding-left pl-lg-0">
                  <a
                      class="subtitle-1 font-weight-bold"
                      @click="redirectToAddServicePage(service)"
                  >
                    Kiválasztás</a
                  >
                </span>
              </v-col>
            </v-row>
            <template v-slot:actions>
              <vpd-chevron-down></vpd-chevron-down>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container class="vpd-service-panel-placeholder">
              <v-row justify="space-between">
                <v-col class="pt-2">
                  <div class="vpd-service-panel__homepage">
                    <span class="subtitle-2 font-weight-bold">Honlap: </span
                    ><span
                  ><a
                      :href="service.url"
                      class="subtitle-2"
                      target="_blank"
                  >{{ service.urlText }}</a
                  ></span
                  >
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="vpd-service__info body-2 pb-5">
                    <vpd-markdown :source="service.description"/>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="service.hasActiveAccount">
                  <div v-if="service.connectedAccounts.length > 0">
                    <span class="subtitle-1 font-weight-bold"
                    >Ez a szolgáltatás az alábbi bankszámláihoz lett
                      hozzáadva:</span
                    >
                  </div>
                  <v-row
                      v-for="(account, accountIndex) in service.connectedAccounts"
                      :key="account.id"
                      justify="space-between"
                      class="vpd-service-panel__service-accounts-row"
                  >
                    <v-col
                        cols="10"
                        sm="10"
                        md="10"
                        lg="7"
                        class="d-flex jusfity-space-between align-center flex-grow-1"
                    >
                      <img class="small" :src="getBankMiniImgUrl(account.id)"/>
                      <div
                          class="d-flex flex-column flex-lg-row justify-space-between flex-grow-1"
                      >
                        <span class="subtitle-2 ps-3 d-flex">{{
                            account.name
                          }}</span>
                        <div
                            class="d-flex flex-row flex-shrink-0 text-left text--disabled ps-3"
                        >
                          <span class="subtitle-2 font-weight-bold"
                          >Hozzáadva:
                          </span>
                          <span class="subtitle-2 ml-1">{{
                              account.attachedAt | moment()
                            }}</span>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                        cols="0"
                        sm="0"
                        md="0"
                        lg="2"
                        class="d-none d-lg-flex align-center"
                    >
                      <span
                          v-if="moment().isAfter(account.expireAt)"
                          key="connected-account-expiration-state"
                          class="subtitle-2"
                          :class="isWarned(account.expireAt)"
                      >Lejárt {{ since(account.expireAt) }}
                      </span>
                      <span
                          v-else
                          key="connected-account-state"
                          class="subtitle-2"
                          :class="isWarned(account.expireAt)"
                      >Lejár {{ since(account.expireAt) }}
                      </span>
                    </v-col>
                    <v-col
                        cols="2"
                        md="2"
                        sm="2"
                        lg="3"
                        class="d-flex align-center flex-end pa-0"
                    >
                      <v-spacer/>
                      <span @click.stop="
                          openDialogForSelectedRow(
                            service.id,
                            account.id,
                            serviceIndex,
                            accountIndex)">
                        <vpd-icon-x
                          color="grey"
                          class="d-block d-lg-none"
                      ></vpd-icon-x>
                      </span>
                      <span class="d-none d-lg-flex align-center"
                      ><a
                          href="#"
                          @click.stop="
                            openDialogForSelectedRow(
                              service.id,
                              account.id,
                              serviceIndex,
                              accountIndex
                            )
                          "
                      >Megosztás eltávolítása</a
                      ></span
                      >
                    </v-col>
                  </v-row>
                  <vpd-confirm-dialog
                      headline="Valóban le szeretné állítani a megosztást?"
                      subtitle="A leállítást követően adatai nem kerülnek továbbításra a kiválasztott partnerhez. 
                      Ezt azt is eredményezheti, hogy a továbbiakban nem fogja tudni igénybe venni partnerénél azt a szolgáltatást, amihez korábban a megosztást beállította.
                      "
                      :dialog="dialog"
                      event="remove-service-from-account"
                      v-on:remove-service-from-account="removeAccountFromService"
                      v-on:close-modal="dialog = false"
                  ></vpd-confirm-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import "./_vpd-service-panel.scss";
import {getBankMiniImgUrl, getServiceImgUrl} from "@/modules/helpers";
import moment from "moment";
import VpdConfirmDialog from "@/components/vpd-confirm-dialog/vpd-confirm-dialog";
import VpdChevronDown from "@/components/icons/VpdChevronDown";
import VpdIconX from "@/components/icons/VpdIconX";
import servicesService from "@/services/services-service";
import VpdMarkdown from "@/components/vpd-markdown/vpd-markdown";

export default {
  name: "vpd-service-panel",
  props: {
    services: {
      type: Array,
      required: true,
    },
    accounts: {
      type: Array,
      required: true,
    },
  },
  components: { VpdIconX, VpdChevronDown, VpdConfirmDialog, VpdMarkdown },
  data: function () {
    return {
      dialog: false,
      apiName: "RestApi",
      selectedServiceId: null,
      selectedAccountId: null,
      selectedServiceIndex: null,
      selectedAccountIndex: null,
      nextExpiring: this.findExpiring(this.services),
      values: {},
      activeAccountIds: [],
    };
  },
  computed: {
    state: {
      set: function (val) {
        this.$store.dispatch("general/panelState", val);
      },
      get: function () {
        return this.$store.getters["general/panelState"];
      },
    },
  },
  methods: {
    openDialogForSelectedRow(serviceId, accountId, serviceIndex, accountIndex) {
      this.dialog = true;
      this.selectedServiceId = serviceId;
      this.selectedAccountId = accountId;
      this.selectedServiceIndex = serviceIndex;
      this.selectedAccountIndex = accountIndex;
    },
    async removeAccountFromService() {
      this.dialog = false;
      await servicesService.removeAccountFromService(
          this.selectedServiceId,
          this.selectedAccountId
      );
      this.removeAccountFromServiceByIndex(
          this.selectedServiceIndex,
          this.selectedAccountIndex
      );
      this.selectedServiceIndex = null;
      this.selectedAccountIndex = null;
    },
    isWarned(date) {
      return moment
          .duration(moment().diff(moment(date, "YYYY/MM/DD-HH:mm:ss")))
          .asDays() < 10
          ? "red--text"
          : "";
    },
    connectAction(count) {
      return count
          ? {
            text: `${count} számládhoz csatolva`,
            action: () => (this.state = 0),
          }
          : {
            text: "",
            /*text: "Hozzáadás",
            action: () => this.redirectToAddServicePage(),*/
          };
    },
    findExpiring(services) {
      return services.length
          ? services.reduce((exp, current) =>
              exp.expires < current.expires ? exp : current
          )
          : {};
    },
    getBankMiniImgUrl(accountId) {
      const bank = this.accounts.filter(account => account.id === accountId)[0]?.bank;
      return getBankMiniImgUrl(bank);
    },
    getServiceImgUrl(service) {
      return getServiceImgUrl(service.id);
    },
    //Reformat mock markdown- should remove in production
    cleanMarkDown(markDownText) {
      return markDownText
          .replaceAll("          ", "")
          .replaceAll("# ", "#### ");
    },
    moment: function (date) {
      return moment(date);
    },
    since(date) {
      moment.locale("hu");
      return moment(date, "YYYY/MM/DD-HH:mm:ss").fromNow();
    },
    removeAccountFromServiceByIndex(serviceIndex, accountIndex) {
      this.services[serviceIndex].numberOfConnectedAccounts =
          this.services[serviceIndex].numberOfConnectedAccounts - 1;
      this.services[serviceIndex].connectedAccounts.splice(accountIndex, 1);
    },
    redirectToAddServicePage(service) {
      localStorage.setItem("serviceId", service.id)
      this.$router.push({name: "add-services"});
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  created() {
    this.activeAccountIds = this.accounts.filter(account => account.isValid === "true").map(account => account.id);
    this.services.forEach(service => {
      if (service.connectedAccounts.length == 0) {
        service["hasActiveAccount"] = false;
      }
      
      service.connectedAccounts.forEach(connectedAccount => {
        if (this.activeAccountIds.includes(connectedAccount.id)) {
          service["hasActiveAccount"] = true;
        }
        else {
          service["hasActiveAccount"] = false;
        }
      })
    })
  }
};
</script>
