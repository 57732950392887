<template>
  <v-container class="vpd-privacy-policy" fluid>
    <vpd-md-viewer
      :source="source"
      title="Cookie szabályzat"
    />
  </v-container>
</template>

<script>
import VpdMdViewer from "../components/vpd-md-viewer/vpd-md-viewer";
import Doc from "../assets/cookie-policy.md";

export default {
  name: "cookie-policy",
  components: {
    VpdMdViewer,
  },
  data() {
    return {
      source: Doc,
    };
  },
  methods: {},
};
</script>