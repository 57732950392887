import axiosService from "@/modules/axios-service";
import { Auth } from "aws-amplify";

class DeleteService {
  path = "/user/deleterequests";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async deleteUser(code) {
    return await axiosService
      .put(this.path, {
        code: code,
      })
      .then(() => {
        return "Sikeresen törölte a felhasználóját!";
      })
      .catch((error) => {
        if (error.status === 400) {
          return "A link lejárt!";
        } else {
          return "Valami nem jól működik, kérjük vegye fel a kapcsolatot az ügyfélszolgálattal.";
        }
      });
  }
}

export default new DeleteService();
