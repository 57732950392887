<template>
  <div v-if="selectedServiceId" class="vpd-available-accounts-panel">
    <span class="subtitle-1">
      Kérjük erősítse meg, hogy melyik bankszámlájára vonatkozzon a számlatörténet megosztása!
    </span>
    <v-list two-line>
      <v-list-item-group v-model="selected" multiple>
        <template v-for="(account, index) in accounts">
          <v-list-item :key="account.id" :disabled="account.alreadyConnected">
            <div class="img-container">
              <img :src="getBankMiniImgUrl(account.bank)" />
            </div>
            <v-list-item-content class="d-flex align-center">
              <v-list-item-title>{{ account.name }} - {{account.currency }}</v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="account.headline"
              ></v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ account.bban }}
              </v-list-item-subtitle>
              <span v-if="account.alreadyConnected" class="d-block d-lg-none"
                >Már hozzáadva</span
              >
            </v-list-item-content>
            <div
              class="d-flex align-center"
              v-if="!account.alreadyConnected"
              key="available-account-connected-state"
            >
              <v-list-item-action class="mr-0">
                <v-checkbox
                  :input-value="selected"
                  :value="index"
                  color="secondary"
                  class="available-accounts-panel-checkbox d-flex align-center"
                ></v-checkbox>
              </v-list-item-action>
            </div>
            <div v-else key="available-account-connected-state">
              <v-list-item-action
                class="d-flex flex-row align-center justify-space-between mr-0"
              >
                <span class="d-none d-lg-block">Már hozzáadva</span
                ><v-checkbox
                  disabled
                  readonly
                  input-value="true"
                  class="ps-10 available-accounts-panel-checkbox d-flex align-center"
                ></v-checkbox>
              </v-list-item-action>
            </div>
          </v-list-item>
          <v-divider
            v-if="index + 1 < accounts.length && $vuetify.breakpoint.mobile"
            :key="index"
          ></v-divider>
        </template>
        <span v-if="isSelectedWithoutActiveAccount" class="subtitle-1">
          Jelenleg nincsen aktív bankszámlája!
        </span>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import "./_vpd-available-accounts-panel.scss";
import { getBankMiniImgUrl } from "@/modules/helpers";

export default {
  data() {
    return {
      selected: [],
      isSelectedWithoutActiveAccount: false,
    };
  },
  name: "vpd-available-accounts-panel",
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    selectedServiceId: {
      type: String,
      required: true
    }
  },
  watch: {
    accounts: function () {
      this.selected.splice(0, this.selected.length);
    },
    selected: function () {
      if (this.accounts.length == 0) {
        this.isSelectedWithoutActiveAccount = true;
      }
      this.$emit("selected-accounts-changed", { length: this.selected.length });
    },
  },
  methods: {
    getBankImgUrl(bank) {
      return getBankImgUrl(bank);
    },
    getBankMiniImgUrl(bank) {
      return getBankMiniImgUrl(bank);
    },
    getFormattedAccountNumber: function (accountNumber) {
      let formattedAccountNumber =
        accountNumber.substring(0, 8) + "-" + accountNumber.substring(16, 8);
      if (accountNumber.length > 16) {
        formattedAccountNumber += "-" + accountNumber.substring(24, 16);
      }
      return formattedAccountNumber;
    },
  },
};
</script>
