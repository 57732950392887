<template>
  <v-container
    class="vpd-content"
    v-if="!this.showNotification"
    key="consents-state"
    fluid
  >
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card>
      <vpd-card-title title="Csatlakoztatható bankszámlák"></vpd-card-title>
      <v-alert
        v-model="alert"
        :type="alertType"
        close-text="Bezárás"
        dismissible
        @click="alert = false"
        >{{ alertMessage }}
      </v-alert>
      <vpd-consent-account-panel
        v-if="accounts[0]"
        :accounts="accounts"
        ref="accountpanel"
      >
      </vpd-consent-account-panel>
      <v-row justify="center">
        <v-col align="center">
          <v-btn class="primary fix-width" @click="submit" v-if="accounts[0]"
            >Kiválasztott számlák csatolása</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
  <v-container class="vpd-content" v-else key="consents-state">
    <vpd-notification-panel
      :titleText="notificationTitle"
      :bodyText="notificationText"
      :buttonRoute="buttonRoute"
      :buttonText="buttonText"
    />
  </v-container>
</template>

<script>
import VpdConsentAccountPanel from "@/components/vpd-consent-account-panel/vpd-consent-account-panel";
import VpdNotificationPanel from "@/components/vpd-notification-panel/vpd-notification-panel";
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
import accountsService from "@/services/accounts-service";
import consentsService from "@/services/consents-service";

const notificationTitle = "Hiba a bankszámla csatolás során";
const buttonRoute = "/";
const buttonText = "Vissza a főoldalra";

export default {
  data() {
    return {
      apiName: "RestApi",
      overlay: true,
      alertType: "error",
      alertMessage: "Valami hiba történt a szerveren!",
      alert: false,
      selected: [],
      accounts: [],
      accountsLength: 0,
      availableAccountsEndpoint: "",
      consent: "/consents",
      tokenId: "",
      notificationTitle: "",
      notificationText: "",
      buttonRoute: "",
      buttonText: "",
      showNotification: false,
    };
  },

  props: ["config"],

  components: {
    VpdConsentAccountPanel,
    VpdNotificationPanel,
    VpdCardTitle,
  },

  methods: {
    countAccounts() {
      this.accountsLength = this.accounts.length;
    },

    async submit() {
      const selected = this.$refs.accountpanel.selected;
      if (selected.length === 0) {
        this.redirectHome();
      }
      const items = this.getSelectedAccounts(selected);
      this.overlay = true;
      const params = {
        tokenId: this.tokenId,
        accounts: items.map(function (item) {
          return item.accountId;
        }),
      };

      await consentsService.submit(params).then(() => {
        this.overlay = false;
        this.redirectHome();
      });
    },

    init() {
      this.config.aspspProviders.forEach((value) => {
        if (value.id === this.$route.params.id) {
          this.availableAccountsEndpoint = value.auth.availableAccounts;
        }
      });
    },

    getSelectedAccounts(selected) {
      const array = [];
      selected.forEach((value) => {
        array.push({ accountId: this.accounts[value].accountId });
      });
      return array;
    },

    validateRoute() {
      let valid = false;
      this.config.aspspProviders.forEach((value) => {
        if (value.id === this.$route.params.id) {
          valid = true;
        }
      });
      if (!valid) {
        this.$router.push({ name: "404" });
      }
    },

    redirectHome() {
      if (this.$cookie.get("source") === "leanpay") {
          window.location.href = "https://www.leanpay.demoportal.bankszamlakivonat.hu/accounts"
        }
      this.$router.push({ name: "accounts" }).catch(()=>{});
    },
  },

  async created() {
    this.notificationTitle = notificationTitle;
    this.buttonRoute = buttonRoute;
    this.buttonText = buttonText;
    const { status, tokenId, message } = this.$route.query;

    this.validateRoute();
    this.init();
    switch (status) {
      case "success":
        this.redirectHome();
        break;
      case "error":
        this.notificationText = message;
        this.showNotification = true;
        break;
      case "consentsrequired":
        this.tokenId = tokenId;

        this.overlay = true;
        // load consent data
        const consentData = await accountsService.fetchAccountsForConsent(
          this.availableAccountsEndpoint + this.tokenId
        );
        consentData.items.forEach((account) => {
          this.accounts.push({
            accountId: account.id,
            title: account.name,
            subtitle: account.bban,
            connected: account.connected,
            currency: account.currency,
          });
        });
        this.countAccounts();
        this.overlay = false;
        break;
      default:
        break;
    }
  },
};
</script>
