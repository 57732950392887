<template>
  <div class="vpd-cookie-banner" v-show="bannerStatus || showBanner">
    <v-row no-gutters>
      <v-banner class="banner" outlined sticky rounded two-line elevation="3">
        <vpd-cookie-banner-message v-if="!showCookieSettings"/>
        <vpd-cookie-banner-settings v-if="showCookieSettings" :enableNecessaryCookies="enableNecessaryCookies" :enableAnalyticCookies="enableAnalyticCookies"
                                          @updateNecessaryCookie="setNecessaryCookie" @updateAnalyticCookie="setAnalyticCookie"/>
        <template v-slot:actions>
          <div class="vpd-cookie-banner-buttons">
            <v-btn class="vpd-cookie-banner-button" @click="setCookies()"
            text
            color="black"
            >
            {{cookieButton}}
            </v-btn>
            <!-- <v-btn class="vpd-cookie-banner-button"  @click="hideCookieBanner()"
            text
            color="primary"
            >
            Elutasítás
            </v-btn> -->
            <v-btn class="vpd-cookie-banner-button" @click="enableCookies()"
            text
            color="#202cb2e6"
            >
            Elfogadás
            </v-btn>
          </div>
        </template>
      </v-banner>
    </v-row>
  </div>
</template>

<script>
import "./_vpd-cookie-banner.scss";
import VpdCookieBannerMessage from "../vpd-cookie-banner-message/vpd-cookie-banner-message";
import VpdCookieBannerSettings from "../vpd-cookie-banner-settings/vpd-cookie-banner-settings";
import Vue from 'vue';
import VueMHotjar from "../../services/hotjar-service";
import { bootstrap } from 'vue-gtag';

export default {
  props: ["showBanner"],
  components: {
    VpdCookieBannerMessage,
    VpdCookieBannerSettings,
  },
  data:() => ({
    bannerStatus: Vue.cookie.get("accepted") ? false : true,
    enableNecessaryCookies: true,
    enableAnalyticCookies: Vue.cookie.get("accepted") && !Vue.cookie.get("statistical") ? false : true,
    showCookieSettings: false,
    cookieButton: "Beállítások",
  }),

  methods: {
    hideCookieBanner() {
      this.bannerStatus = false;
      this.$emit("hideCookieBanner")
    },
    enableCookies() {
      this.bannerStatus = false;
      this.$cookie.set("accepted", 1, { expires: '3M' });
      const statisticalCookieExist = this.$cookie.get("statistical");
      
      if (this.enableAnalyticCookies) {
        if (!statisticalCookieExist) {
          this.$cookie.set("statistical", 1, { expires: '3M' });
          this.addGoogleAnalytics();
        }
      }

      if (!this.enableAnalyticCookies) {
        if (statisticalCookieExist) {
          this.$cookie.delete("statistical");
          this.deleteGoogleAnalytics();
        }        
      }
      
      this.$emit("hideCookieBanner")
    },
    setCookies() {
      this.showCookieSettings = !this.showCookieSettings;
      this.cookieButton = this.showCookieSettings ? "Elrejtés" : "Beállítások";
    },
    setNecessaryCookie() {
      this.enableNecessaryCookies = !this.enableNecessaryCookies;
    },
    setAnalyticCookie() {
      this.enableAnalyticCookies = !this.enableAnalyticCookies;
    },
    addHotjar() {
      VueMHotjar.install(Vue, {id: "2998467"})
    },
    deleteHotjar() {
      var head = document.getElementsByTagName("head")[0];
      var scripts = [...document.querySelectorAll('script')];
      var hotjarScriptProd = scripts.filter(element => {
        return element.src.includes("script.hotjar.com")
      });
      if (hotjarScriptProd[0]) {
        head.removeChild(hotjarScriptProd[0]);
      }
      head = document.getElementsByTagName("head")[0];
      var hotjarScript = document.getElementById("hotjar");
      head.removeChild(hotjarScript);
    },
    addGoogleAnalytics() {
      bootstrap().then((gtag) => {
      })
    },
    deleteGoogleAnalytics() {
      this.$cookie.delete("_ga_QTKHWT5X2J", {domain: "." + window.location.hostname});
      this.$cookie.delete("_ga", {domain: "." + window.location.hostname});
    }
  },
};
</script>
