<template>
  <v-card class="vpd-banklist-panel">
    <v-overlay :value="overlay" z-index="1001">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-confirm-dialog name="banklist-confirm-dialog"
        z-index="1010"
        headline="Figyelem"
        subtitle="A számlacsatolás első lépéseként bankja az alábbi adatok megadását kéri. Ezt követően majd átirányítjuk a bank oldalára, ahol a bank elvégzi a további beazonosítást."
        :dialog="dialog"
        :bankId="selectedBankId"
        :schema="schema"
        event="redirect-to-bank"
        v-on:redirect-to-bank="redirectToSelectedBankUrl"
        v-on:close-modal="cancelDialog"
    >
    </vpd-confirm-dialog>
    
    <div class="banklist-dialog-header">
       <div class="banklist-dialog-title headline">Válassza ki a számlavezető bankját</div>
        <v-btn icon v-on:click="$emit('close-dialog')" color="red"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
    </div>
    <div class="d-flex flex-column gap-4">
      <div class="d-flex elevation pointer" v-for="provider in config.aspspProviders" :key="provider.id" @click="selectBank(provider)">
        <div class="d-flex align-center">
          <v-img
            :src="provider.meta.image"
            contain
            class="bank-logo"
            :class="provider.meta.class"
          >
          </v-img>
          <span class="font-medium">{{ provider.label }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import "./_vpd-banklist-panel.scss";
import bankListService from "@/services/banklist-service";
import VpdConfirmDialog from "@/components/vpd-confirm-dialog/vpd-confirm-dialog";
import bankRequirementsService from "../../services/bank-requirements-service";

export default {
  components: {VpdConfirmDialog},
  props: ["config"],
  data() {
    return {
      dialog: false,
      active: false,
      overlay: false,
      apiName: "RestApi",
      path: null,
      selectedBank: null,
      selectedBankId: null,
      schema: null,
    };
  },

  methods: {
    async selectBank(selectedBank) {
      this.selectedBankId = selectedBank.id
      this.setLoading(true);
      bankRequirementsService.get(selectedBank.id).then((resp) => {
        if (!resp.items) {
          this.redirectToSelectedBankUrl()
          return;
        }
        this.schema = resp.items;
        this.setLoading(false);

        this.dialog = true;
      });
      this.path = selectedBank.auth.redirect;
    },
    cancelDialog() {
      this.dialog = false;
      this.path = null;
    },
    
    async redirectToSelectedBankUrl(formValues=null) {
      const requestBodyObject = {};
      if (!formValues) {
        this.redirect(requestBodyObject)
        this.setLoading(false);
      }

      const keys = Object.keys(formValues);

      keys.forEach(key => {
        requestBodyObject[key.toLocaleLowerCase()] = formValues[key]
      })

      this.dialog = false;
      this.setLoading(true);
      this.redirect(requestBodyObject)
    },
    async redirect(requestBodyObject) {
      this.setLoading(true);
      await bankListService.select(this.path, requestBodyObject).then((resp) => {
          if (resp !== undefined) {
            window.location = resp.url;
          }
          this.setLoading(false);
        });
      },
    setLoading: function (value) {
      this.overlay = value;
    },
  },
};
</script>
