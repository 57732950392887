class Hotjar {
    constructor (id, snippetVersion) {
      // Default hotjar.com snippet
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
        h._hjSettings = {
          hjid: id, // Hotjar ID
          hjsv: snippetVersion // Hotjar Snippet Version
        }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script');
        r.setAttribute("id", "hotjar"); r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(
        window,
        document,
        'https://static.hotjar.com/c/hotjar-',
        '.js?sv='
      )
    }
}

class VueMHotjar {
    
    init(options) {
        try {
          const { id, snippetVersion = 6, isProduction = true } = options
          if (isProduction) {
            // eslint-disable-next-line no-new
            new Hotjar(id, snippetVersion)
            return window.hj
          } else {
            console.log('%c 🔥 HotJar Tracking Disabled 🔥', 'color: #fff; background: #35495d; font-size: 14px; border-radius: 5px; padding: 10px 5px; margin: 20px 0;')
            return false
          }
        } catch (error) {
          console.error(error)
          return false
        }
      }
    
      install(Vue, options) {
          Vue.prototype.$hj = this.init(options)
          Vue.prototype.$hjOptions = options
      }
}

export default new VueMHotjar();


