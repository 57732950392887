<template>
  <v-container class="vpd-sub-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-account-summary
      v-if="accounts[0]"
      title="Számlák összértéke: "
      subtitle=""
      :summaries="summaries"
      :lastSynchronizationDate="lastSynchronizationDate"
      @manualRefresh="manualRefresh"
    />
    <v-divider v-if="accounts[0]" />
    <vpd-account-panel v-if="accounts[0]" :accounts="accounts" :config="config" @refreshUserData="refreshUserData">
    </vpd-account-panel>
    <div class="d-flex justify-center align-center" v-if="accounts[0]">
      <v-btn class="primary huge px-12 my-6" @click.stop="dialog = true"
        >Új bankszámla csatlakoztatása</v-btn
      >
    </div>
    <div v-if="!accounts[0]" class=".vpd-account-summary py-5">
      <span class="subtitle-1">Még nincs bankszámla csatlakoztatva</span>

      <v-divider />
      <div class="d-flex justify-center align-center">
        <v-btn class="primary huge px-12 my-6" @click.stop="dialog = true"
          >Új bankszámla csatlakoztatása</v-btn
        >
      </div>
    </div>

    <v-dialog v-model="dialog" content-class="v-dialog--custom">
      <vpd-banklist-panel v-on:close-dialog="closeDialog" :config="config" :active.sync="dialog" />
      
    </v-dialog>
    <vpd-transactions
      v-if="accounts[0]"
      @jump="jumpPage"
      justify="space-between"
      title="Összesített tranzakciólista"
      :length="transactionsLength"
      :transactions="transactions"
      :loading="loadingTransactions"
      :showFootPrint="showFootPrint"
    />
  </v-container>
</template>

<script>
import VpdAccountSummary from "@/components/vpd-account-summary/vpd-account-summary";
import VpdTransactions from "@/components/vpd-transactions/vpd-transactions";
import VpdAccountPanel from "@/components/vpd-account-panel/vpd-account-panel";
import VpdBanklistPanel from "@/components/vpd-banklist-panel/vpd-banklist-panel";
import accountsService from "@/services/accounts-service";
import transactionsService from "@/services/transactions-service";
import VpdEventBus from "../modules/vpd-event-bus";

export default {
  data() {
    return {
      dialog: false,
      loadingTransactions: false,
      loadingAccounts: false,
      summaries: [],
      transactions: [],
      accounts: [],
      accountNumbers: [],
      transactionsTotalCount: null,
      transactionsItemsPerPage: 15,
      lastEvaluatedAccountKey: null,
      transactionsLength: 1,
      overlay: false,
      lastSynchronizationDate: '',
      showFootPrint: '',
    };
  },
  name: "home",
  components: {
    VpdBanklistPanel,
    VpdAccountPanel,
    VpdTransactions,
    VpdAccountSummary,
  },
  props: ["config"],
  methods: {
    jumpPage: async function (page) {
      if (page < 1 || page > this.transactionsLength) return;
      let skip = (page - 1) * this.transactionsItemsPerPage;
      const transactionsData = await transactionsService.fetchTransactions(
        skip,
        this.transactionsItemsPerPage
      );
      this.loadTransactionsData(transactionsData);
    },
    loadAccountsData: function (data) {
      this.accounts = data.items.filter(account => account.isValid.toLowerCase() === "true" || account.deletedAt);
      this.summaries = data.summaries;
      this.lastSynchronizationDate = data.lastSynchronizationDate.substring(0,16);
      this.showFootPrint = data.showFootPrint;
      this.$store.commit("setAccountsCount", this.accounts.length);
    },
    loadTransactionsData: function (data) {
      let pageNumber = data.totalCount / this.transactionsItemsPerPage;
      this.transactionsLength = Math.ceil(pageNumber);
      this.transactions = data.items;
    },
    setLoading: function (value) {
      this.overlay = value;
      this.loadingAccounts = value;
      this.loadingTransactions = value;
    },
    closeDialog: function(){
            this.dialog = false;
    },
    refreshAccounts: async function () {
      const accountsData = await accountsService.fetchAccounts();
      if (accountsData) this.loadAccountsData(accountsData);
    },
    refreshTransactions: async function () {
      const transactionsData = await transactionsService.fetchTransactions(
        "0",
        this.transactionsItemsPerPage
      );
      if (transactionsData && transactionsData.totalCount > 0)
        this.loadTransactionsData(transactionsData);
    },
    refreshUserData: async function () {
      await this.refreshAccounts();
      await this.refreshTransactions();
    },
    manualRefresh: async function () {
      this.setLoading(true);
      await accountsService.refreshAccounts().then(() => this.setLoading(false));
      this.setLoading(false);

      await this.refreshUserData();
    },
  },
  async created() {
    VpdEventBus.$on("refresh-account", this.refreshAccounts);
    VpdEventBus.$on("refresh-transaction", this.refreshTransactions);

    this.$store.commit("setAccountsCount", 0);
    this.setLoading(true);

    await this.refreshAccounts();
    await this.refreshTransactions();

    this.setLoading(false);
  },
  destroyed() {
    VpdEventBus.$off("refresh-account", this.refreshAccounts);
    VpdEventBus.$off("refresh-transaction", this.refreshTransactions);
  },
};
</script>
