const actions = {
  /* State machine flags */
  SHOW_CONFIRMATION({ commit }) {
    commit("component", "vpd-confirm-panel");
  },

  SHOW_REGISTRATION({ commit }) {
    commit("component", "vpd-registration-panel");
  },
};

export default actions;
