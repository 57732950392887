const mutations = {
  panelState(state, arg) {
    state.panelState = arg;
  },
  userState(state, arg) {
    state.userState = arg;
  },
};

export default mutations;
