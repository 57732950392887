<template>
  <v-card class="vpd-panel vpd-cashbook-panel">
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-card-title :title="title"></vpd-card-title>
    <v-alert
      v-model="alert"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertMessage }}
    </v-alert>
    <v-row justify="center">
      <v-col align="center" class="pb-10">
        <div class="description fix-width">
         {{ subTitle }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align="center">
        <v-btn
          :disabled="!canReturnToHome"
          class="primary huge fix-width"
          @click="redirectToHome"
          >Tovább
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
import servicesService from "@/services/services-service";

export default {
  name: "cashbook-sso",
  props: ["config"],
    data: () => ({
      alert: false,
      alertMessage: "",
      alertType: "error",
      overlay: true,
      title: "",
      subTitle: "",
      canReturnToHome: false
  }),
  components: {
      VpdCardTitle
  },
  methods: {
    redirectToHome() {
      this.$router.push({ name: "accounts" });
    },
  },
  created() {
    const path = this.$route.path;
    if (path.includes('succeeded')) {
      this.title = "Cashbook megosztás";
      this.subTitle = "Megosztás ellenőrzése folyamatban..."
    } else {
      this.title = "Hiba a megosztás során";
      this.overlay = false;
      this.canReturnToHome = true;
      return;
    }
    if (this.$route.query && this.$route.query.sso) {
      servicesService.confirmSsoTokenForCashbook(this.$route.query.sso)
        .then(() => {
          this.subTitle = "Sikeres megosztás, tovább a főoldalra."
          this.overlay = false;
          this.canReturnToHome = true;
        })
        .catch(() => {
          this.alertMessage = "Hiba történt a szerveren!";
          this.overlay = false;
          this.alertType = "error";
          this.alert = true;
          this.subTitle = "";
          this.canReturnToHome = true;
        });
    } else {
      this.title = "Hiba a megosztás során";
      this.subTitle = ""
      this.overlay = false;
      this.canReturnToHome = true;
    }
  },
};
</script>
  