const images = {
  logos: {
     otp: {
      original: require("./logos/originals/otp-bank-logo.svg"),
      mini: require("./logos/mini/OTP.png"),
      thumbnail: require("./logos/originals/otp-bank-logo.svg"),
    },
    magnet: {
      original: require("./logos/originals/magnet-bank-logo.png"),
      mini: require("./logos/mini/Magnet.png"),
      thumbnail: require("./logos/originals/magnet-bank-logo.png"),
    },
    cib: {
      original: require("./logos/originals/cib-bank-logo.svg"),
      mini: require("./logos/mini/CIB.png"),
      thumbnail: require("./logos/originals/cib-bank-logo.svg"),
    },
    unicredit: {
      original: require("./logos/originals/unicredit-bank-logo.svg"),
      mini: require("./logos/mini/Unicredit.png"),
      thumbnail: require("./logos/originals/unicredit-bank-logo.svg"),
    },
    revolut: {
      original: require("./logos/originals/revolut-logo.svg"),
      mini: require("./logos/mini/Revolut.png"),
      thumbnail: require("./logos/originals/revolut-logo.svg"),
    },
    raiffeisen: {
      original: require("./logos/originals/raiffeisen-bank-logo.svg"),
      mini: require("./logos/mini/Raiffeisen.png"),
      thumbnail: require("./logos/originals/raiffeisen-bank-logo.svg"),
    },
    kh: {
      original: require("./logos/originals/kh-logo.png"),
      mini: require("./logos/mini/KH.png"),
      thumbnail: require("./logos/originals/kh-logo.png"),
    },
    budapest: {
      original: require("./logos/originals/budapest-bank-logo.svg"),
      mini: require("./logos/mini/BB.png"),
      thumbnail: require("./logos/originals/budapest-bank-logo.svg"),
    },
    erste: {
      original: require("./logos/originals/erste-bank-logo.svg"),
      mini: require("./logos/mini/Erste.png"),
      thumbnail: require("./logos/thumbnails/erstebank.svg"),
    },
    granit: {
      original: require("./logos/originals/granit-logo.jpg"),
      mini: require("./logos/mini/Granit.png"),
      thumbnail: require("./logos/originals/granitbank-simple.svg"),
    },
    wise: {
      original: require("./logos/originals/wise-logo.png"),
      mini: require("./logos/originals/wise-logo.png"),
      thumbnail: require("./logos/originals/wise-logo.png"),
    },
    takarek: {
      original: require("./logos/originals/takarekbank-zrt-logo.svg"),
      mini: require("./logos/mini/Takarek.png"),
      thumbnail: require("./logos/originals/takarekbank-zrt-logo.svg"),
    },
    mkb: {
      original: require("./logos/originals/mkb-bank-logo.jpg"),
      mini: require("./logos/mini/MKB.jpg"),
      thumbnail: require("./logos/originals/mkb-bank-logo.jpg"),
    },
    nobank: {
      original: require("./logos/originals/nobankicon.svg"),
      thumbnail: require("./logos/originals/nobankicon.svg"),
    },
    //Sample
    "429c30f3-e5d9-4c1c-a44f-53555d313abd": {
      original: require("./logos/originals/erste-bank-logo.svg"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "0f41f27e-e18f-4dc1-991a-de8c38bb9e06": {
      original: require("./logos/originals/billzone-logo.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "f8756a3f-badf-4bc2-9681-5a8ab5215195":{
      original: require("./logos/originals/finistic-logo.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "b812cfd0-c0d8-4cdb-8ddd-b73e4ebff563":{
      original: require("./logos/originals/netaccounting.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "86cb974c-8579-4d9a-999a-f7b5cf0bb2ef":{
        original: require("./logos/originals/dyc.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "0c470a51-b6f2-4683-8c54-5cc1ca08b881":{
      original: require("./logos/originals/logo_szlogan_egymas_alatt_szines.png"),
    thumbnail: require("./logos/originals/noservice.svg"),
    },
    "0906e55f-9fab-44e9-a8f7-80fbf05420be":  {
      original: require("./logos/originals/konto-online-logo-2.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "ffb054af-185d-425c-a838-832d633f59e0": {
      original: require("./logos/originals/kh-logo.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "2e357456-506c-429e-92e7-92551f27f2f1": {
      original: require("./logos/originals/cashbook-logo.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    "0b4be870-7bfb-4183-bb01-7a2ddfc20e63": {
      original: require("./logos/originals/cegmenedzser-logo.png"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
    noservice: {
      original: require("./logos/originals/noservice.svg"),
      thumbnail: require("./logos/originals/noservice.svg"),
    },
  },
  icons: {
    facebook: require("./icons/facebook.svg"),
    google: require("./icons/google.svg"),
  },

  underConstruction: {
    small: require("./under-construction/mobil-under-construction.png"),
    big: require("./under-construction/desktop-under-construction.png")
  },
};

export default images;
