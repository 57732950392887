<template>
  <div class="py-0">
    <div class="welcome-text justify-center">
      <v-dialog v-model="showBanklistDialog" content-class="v-dialog--custom">
        <vpd-banklist-panel v-on:close-dialog="closeDialog" :config="config"/>
      </v-dialog>
      <vpd-confirm-dialog
          headline="Számlatörténetének megosztása előtt csatlakoztassa a bankszámláját"
          subtitle="Első lépésben csatlakoztassa bankszámláját és ezután jelölje ki, hogy kivel szeretné megosztani a bankszámlatörténetét. 
          Elindítja a bankszámla csatlakoztatást most?"
          :dialog="showDialog"
          event="add-new-account"
          v-on:add-new-account="showBanklistDialog = true"
          v-on:close-modal="showDialog = false"
      ></vpd-confirm-dialog>
      <div v-if="!isOpten" class="d-flex flex-column align-center">
        <h1>Üdvözöljük!</h1>
        <span v-if="!showButton"
        ><p>Ezen az oldalon keresztül tudja megosztani bankszámlatörténetét:</p>
          <ul>
            <li>Hiteligényléshez a kiválasztott bankjával, vagy</li>
            <li>Számlázó programjával, könyvelőjével a kimenő számlái és a bejövő utalásai összepontozásához</li>
          </ul>
        </span>
        <span v-if="showButton">
          <ol>
            <li>Első lépésben csatlakoztassa bankszámláját</li>
            <li>Majd válassza ki, hogy kivel szeretné megosztani a számlatörténetét</li>
            <li>Biztonságos csatornán célba juttatjuk adatait</li>
          </ol>
        </span>
      </div>
      <div v-if="isOpten" class="d-flex flex-column align-start">
        <h1>Üdvözöljük!</h1>
        <span v-if="!showButton"
        ><p>Megvásárolt karbonlábnyom termékéhez az alábbi regisztrációt követően tudja számlatörténetét megosztani, ezáltal a lehető legpontosabb kalkulációt tudjuk elvégezni az Ön vállalkozására. </p>
        </span>
        <span v-if="showButton">
          <ol>
            <li>Bankszámla csatolása</li>
            <li>Számlatörténet megosztása OPTEN - DYC platformmal</li>
            <li>Megosztás jóváhagyása</li>
          </ol>
        </span>
      </div>
    </div>
    <br>
  </div>
</template>
<script>
import "./_vpd-welcome-text.scss";
import VpdConfirmDialog from "@/components/vpd-confirm-dialog/vpd-confirm-dialog";
import VpdBanklistPanel from "@/components/vpd-banklist-panel/vpd-banklist-panel";

export default {
  name: "vpd-welcome-text",
  components: {VpdBanklistPanel, VpdConfirmDialog},
  data() {
    return {
      showDialog: false,
      showBanklistDialog: false,
    };
  },
  props: {
    showButton: {
      type: Boolean,
      required: false,
    },
    config: {
      type: Object,
      required: false,
    },
    accountCount: {
      type: Number,
      required: false,
    },
    isOpten: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    closeDialog: function(){
            this.showBanklistDialog = false;
    },
    addService() {
      if (this.accountCount < 1) {
        this.showDialog = true;
      } else {
        this.redirectToAddServicePage();
      }
    },
    redirectToAddServicePage() {
      this.$router.push({name: "add-services"});
    },
  },
};
</script>
