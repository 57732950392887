<template>
  <div>
    <div class="py-3">
      <span class="headline left">{{ title }}</span>
      <v-spacer></v-spacer>
      <!--v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Keresés"
        single-line
        hide-details
      ></v-text-field-->
    </div>
    <v-divider v-if="!$vuetify.breakpoint.mobile"></v-divider>
    <div>
      <v-expansion-panels accordion>
        <v-expansion-panel
          v-for="transaction in transactions"
          :key="transaction.id"
          class="vpd-account-panel vpd-transaction-expansion-panel"
        >
          <v-expansion-panel-header class="vpd-expand-header-transaction pa-0">
            <v-row class="flex-nowrap flex-row align-center justify-space-between vpd-account-panel-row vpd-margin-bottom">
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="6"
                class="d-flex space-between align-center vpd-panel-transaction-col"
              >
                <img class="vpd-expand-img" :src="getBankMiniImgUrl(transaction.bank)" />
                <div class="ps-3 w-100">
                  <div
                    class="font-weight-medium py-0 vpd-transactions-font-size"
                  >
                    {{ transaction.accountName }}
                  </div>
                  <div v-if="!$vuetify.breakpoint.mobile"
                    class="caption"
                    key="transaction-date"
                  >
                    {{ transaction.date.split(" ")[0] }}
                  </div>
                  <div v-if="$vuetify.breakpoint.mobile" key="account-balance"
                       class="d-flex justify-space-between vpd-transactions-content-width mt-1">
                    <div class="mobile-table-date">
                      <span>{{ transaction.date.split(" ")[0] }}</span>
                      <span v-if="showFootPrint === 'true'" class="mt-1" key="emission">
                        CO<sub>2</sub> kibocsátás: {{ transaction.mainCategory ? transaction.carbonEmissionInGrams + " g" : '-' }}
                      </span>
                    </div>
                    <div class="align-center font-weight-medium vpd-transactions-font-size">{{ transaction.creditDebitIndicator == "CREDIT" ? "+" : "-" }} {{ getNiceValue(transaction.amount, transaction.currency) }}</div>
                  </div>
                </div>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.mobile"
                cols="12"
                sm="12"
                md="12"
                lg="6"
                class="d-flex flex-row-reverse align-self-baseline vpd-panel-transaction-col mr-3"
              >
                <span
                  key="account-balance"
                  class="py-0 font-weight-medium pl-15 pl-lg-0 vpd-transactions-font-size text-right"
                >
                  <div class="transaction-data-container">
                    <span>
                      {{ transaction.creditDebitIndicator == "CREDIT" ? "+" : "-" }} {{ getNiceValue(transaction.amount, transaction.currency) }}
                    </span>
                    <div v-if="showFootPrint === 'true'" class="caption" key="emission">
                      CO<sub>2</sub> kibocsátás: {{ transaction.mainCategory ? transaction.carbonEmissionInGrams + " g" : 'Nincs adat' }}
                    </div>
                  </div>
                </span>

              </v-col>
            </v-row>
            <template v-slot:actions>
              <vpd-chevron-down></vpd-chevron-down>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container class="vpd-account-panel-placeholder">
              <div class="transaction-details vpd-margin-top">
                <div class="d-flex space-between py-0 py-lg-4">
                  <div class="vpd-account-panel__status0">
                    <div class="vpd-transactions-font-size vpd-transactions-content-width" v-if="transaction.creditDebitIndicator == 'CREDIT'">
                      <div v-if="transaction.Sender.AccountNumber">
                        {{transaction.Sender.AccountNumber}}
                      </div>
                      <div v-if="transaction.Sender.Name">
                        {{transaction.Sender.Name}}
                      </div>
                      <div v-if="transaction.transferNote">
                        {{transaction.transferNote}}
                      </div>
                      <div v-if="transaction.transactionType">
                        {{transaction.transactionType}}
                      </div>                      
                    </div>
                    <div class="vpd-transactions-font-size vpd-transactions-content-width" v-if="transaction.creditDebitIndicator == 'DEBIT'">
                      <div v-if="transaction.Recipient.AccountNumber">
                        {{transaction.Recipient.AccountNumber}}
                      </div>
                      <div v-if="transaction.Recipient.Name">
                        {{transaction.Recipient.Name}}
                      </div>
                      <div v-if="transaction.transferNote">
                        {{transaction.transferNote}}
                      </div> 
                      <div v-if="transaction.transactionType">
                        {{transaction.transactionType}}
                      </div>                             
                  </div>
                </div>
                </div>
                <div v-if="showFootPrint === 'true'" class="transaction-category-container d-flex space-between py-0 py-lg-4">
                  <div class="vpd-account-panel__status0">
                    <div class="vpd-transactions-font-size vpd-transactions-content-width">
                      <div v-if="transaction.mainCategory">
                        {{transaction.mainCategory}}
                      </div>
                      <div v-if="transaction.subCategory">
                        {{transaction.subCategory}}
                      </div>
                      <div v-if="transaction.sector">
                        {{transaction.sector}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="length"
          @input="jump"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import "./_vpd-transactions.scss";
import moment from "moment";
import { getFormattedAccountNumber, getFormattedAmountValue, getBankImgUrl, getBankMiniImgUrl } from "@/modules/helpers";
import VpdChevronDown from "@/components/icons/VpdChevronDown";

export default {
  name: "vpd-transactions",
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    length: {
      type: Number,
      required: true,
      validator: function (value) {
        return value >= 0;
      },
    },
    showFootPrint: {
      type: String,
      required: true,
    },
  },
  components: { VpdChevronDown },
  data() {
    return {
      page: 1,
      singleExpand: false,
      search: null,
      headers: [
        // { text: "", align: "start", sortable: false, value: "bankLogo" },
        // { text: "Bank", align: "start", sortable: false, value: "bankName" },
        { text: "Folyószámla", value: "accountName", sortable: false },
        { text: "Dátum", value: "date", sortable: false, width: "120px" },
        { text: "Tranzakciós partner", value: "partnerName", sortable: false },
        { text: "Közlemény", value: "transferNote", sortable: false },
        {
          text: "Összeg",
          align: "right",
          value: "amount",
          sortable: false,
          width: "160px",
        },
      ],
    };
  },
  methods: {
    jump() {
      this.$emit("jump", this.page);
    },
    getFormattedAccountNumber(accountNumber) {
      return getFormattedAccountNumber(accountNumber);
    },
    getNiceValue(value, currency) {
      return getFormattedAmountValue(value, currency);
    },
    getBankImgUrl(bank) {
      return getBankImgUrl(bank);
    },
    getBankMiniImgUrl(bank) {
      return getBankMiniImgUrl(bank);
    },
    moment(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
