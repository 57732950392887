<template>
  <v-icon @click="goBack" :small="$vuetify.breakpoint.mobile"
    >mdi-arrow-left</v-icon
  >
</template>

<script>
export default {
  name: "vpd-go-back",
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
