<template>
  <v-container class="vpd-content" fluid>
    <vpd-profile-panel />
  </v-container>
</template>

<script>
import VpdProfilePanel from "../components/auth/vpd-profile-panel/vpd-profile-panel";

export default {
  name: "user-profile",
  props: ["config"],
  components: {
    VpdProfilePanel,
  },
  data: () => ({
    overlay: false,
  }),
};
</script>
