<template>
  <v-container class="vpd-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div v-if="!isOpten">
      <vpd-welcome-text :isOpten="isOpten" />
      <vpd-hero-image></vpd-hero-image>
    </div>
    <div v-if="isOpten" class="login-view-container">
      <vpd-welcome-text :isOpten="isOpten" />
      <img
        class="dyc-image"
        alt="DYC logo."
        srcset="
          @/assets/dyc_footprint_white-small.png 220w,
          @/assets/dyc_footprint_white-normal.png 440w,
          @/assets/dyc_footprint_white-large.png 880w,
        "
      />
    </div>
    <vpd-login-panel
      @reset-pass="$router.push({ name: 'resetPass' })"
      @signup="$router.push({ name: 'registration' })"
      @submit="submitLogin"
      @federation="handleFederationSignIn"
      ref="loginPanel"
    >
    </vpd-login-panel>
  </v-container>
</template>

<script>
import VpdLoginPanel from "@/components/auth/vpd-login-panel/vpd-login-panel";
import VpdWelcomeText from "../components/vpd-welcome-text/vpd-welcome-text";
import { resendSignUp, signIn } from "../modules/auth";
import { camelCase } from "change-case";
import VpdEventBus from "../modules/vpd-event-bus";
import VpdHeroImage from "@/components/auth/vpd-hero-image/vpd-hero-image";
//import { Hub } from "aws-amplify";

const errorMessages = {
  UserNotConfirmedException: "A felhasználói fiók még nem lett aktiválva!",
  PasswordResetRequiredException:
    "A felhasználói jelszó bellítására van szükség!",
  NotAuthorizedException: "Érvénytelen felhasználónév vagy jelszó!",
  UserNotFoundException: "Hibás vagy nem  létező felhasználó azonosító!",
  success: "Sikeres belépés!",
};

const lockoutErrorMessage = "Zárolt felhasználó.";

export default {
  name: "login",
  props: ["config"],
  components: {
    VpdHeroImage,
    VpdLoginPanel,
    VpdWelcomeText,
  },
  data: () => ({
    overlay: false,
    isOpten: false,
  }),
  methods: {
    async submitLogin(email, password) {
      const panel = this.$refs.loginPanel;
      this.overlay = true;
      const status = await signIn(email, password);
      this.overlay = false;
      if (status.code === "success") {
        if (status.user.attributes.website !== null && status.user.attributes.website === "opten") {
          this.isOpten = true;
          localStorage.setItem("isOpten", true);
        } else {
          this.isOpten = false;
          localStorage.setItem("isOpten", false);
        };
        window.dispatchEvent(new CustomEvent('opten-localstorage-changed', {
            detail: {
              storage: localStorage.getItem('isOpten')
            }
          }));
        return true;
      }
      const handler = `${camelCase(status.code)}Handler`;
      if (typeof this[handler] === "function") {
        this[handler](panel, email);
      } else if (
        status.message === "PreAuthentication failed with error lockout."
      ) {
        panel.showAlert(lockoutErrorMessage, "error");
      } else {
        panel.showAlert(
          `Sikertelen bejelentkezés! ${errorMessages[status.code]}`,
          "error"
        );
      }
    },
    async userNotConfirmedExceptionHandler(panel, email) {
      const signup = await resendSignUp(email);
      if (signup.code !== "success") {
        panel.showAlert(
          "Az érvényesítő kód újraküldése nem sikerült!",
          "error"
        );
      }
    },
    notAuthorizedExceptionHandler(panel, email) {
      panel.showAlert(errorMessages.NotAuthorizedException, "error");
    },
    userNotFoundExceptionHandler(panel, email) {
      panel.showAlert(errorMessages.UserNotFoundException, "error");
    },
    passwordResetRequiredExceptionHandler(panel, email) {
      this.$route.push({ name: "newPass" });
    },
    handleFederationSignIn(provider) {
      VpdEventBus.$emit("redirect", { name: "federated", payload: provider });
    },
  },
  created() {
    this.isOpten = localStorage.isOpten ? JSON.parse(localStorage.isOpten) : false;
  },
};
</script>
