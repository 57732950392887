<template>
  <v-app>
    <vpd-toolbar
      :login-actions="[
        { name: 'login', label: 'Bejelentkezés' },
        { name: 'logout', label: 'Kilépés' },
      ]"
      :isOpten="isOpten"
    />
    <v-main>
      <div class="hero-shape-simple d-block d-lg-none"></div>
      <div class="svg-container d-none d-lg-block">
        <svg
          viewBox="0 0 1440 750"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="1440"
            height="900"
          >
            <rect width="1440" height="900" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1440 412.621C1191.52 616.578 873.555 739 527 739C340.988 739 163.214 703.731 0 639.515V0H1440V412.621Z"
              fill="url(#paint0_linear)"
            />
            <circle
              opacity="0.02"
              cx="548.5"
              cy="-299.5"
              r="683.5"
              fill="white"
            />
            <circle
              opacity="0.02"
              cx="-208.5"
              cy="466.5"
              r="704.5"
              fill="white"
            />
            <circle opacity="0.02" cx="231" cy="251" r="590" fill="white" />
          </g>
          <defs>
            <linearGradient
              v-if="!isOpten"
              id="paint0_linear"
              x1="341"
              y1="1.79405e-06"
              x2="673.789"
              y2="931.351"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#202CB2" />
              <stop offset="1" stop-color="#4A7BE1" />
            </linearGradient>
            <pattern v-else id="paint0_linear" patternUnits="userSpaceOnUse" width="1500" height="1075">
              <image href="@/assets/opten_background.jpg" x="0" y="0" width="1429" height="1075"></image>
            </pattern>
          </defs>
        </svg>
      </div>

      <v-container class="app-body" fluid>
        <notifications group="error" position="top right" style="margin-top:4rem; margin-right:1rem; margin-bottom: 1rem;">
          <template slot="body" slot-scope="props">
            <div style="background-color:#eb722d; border-radius: 8px; padding: 0.5rem;">
                <div style="display:flex; justify-content: space-evenly; gap: 0.5rem;">
                  <v-icon  large color="#ad3c3c">mdi-close</v-icon>
                  <div>
                    <div style="display:flex; justify-content: space-between;">                  
                      <span style="color:white; font-size: 15px; font-weight: 600; margin-bottom: 0px !important;">
                      {{props.item.title}}
                      </span>
                      <a class="close" @click="props.close">
                        <v-icon small color="white">mdi-close-circle-outline</v-icon>
                      </a>
                    </div>
                    <span style="color:white; font-size: 12px; display: block; float: left; width: 15rem !important;">
                      {{props.item.text}}
                    </span>
                  </div>
                </div>
            </div>
          </template>
        </notifications>
        <notifications group="success" position="top right" style="margin-top:4rem; margin-right:1rem; margin-bottom: 1rem;">
          <template slot="body" slot-scope="props">
            <div style="background-color:#47d78a; border-radius: 8px; padding: 0.5rem;">
                <div style="display:flex; justify-content: space-evenly; gap: 0.5rem;">
                  <v-icon  large color="#329f32">mdi-check</v-icon>
                  <div>
                    <div style="display:flex; justify-content: space-between;">                  
                      <span style="color:white; font-size: 15px; font-weight: 600; margin-bottom: 0px !important;">
                      {{props.item.title}}
                      </span>
                      <a class="close" @click="props.close">
                        <v-icon small color="white">mdi-close-circle-outline</v-icon>
                      </a>
                    </div>
                    <span style="color:white; font-size: 12px; display: block; float: left; width: 15rem !important;">
                      {{props.item.text}}
                    </span>
                  </div>
                </div>
            </div>
          </template>
        </notifications>
        <v-row class="content-body" no-gutters>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
        <vpd-cookie-banner @hideCookieBanner="hideCookieBanner" :showBanner="show"/>
                      
        <v-row class="my-0" no-gutters>
          <v-col align="center py-0">
            <footer class="footer">
              <v-row no-gutters>
                <v-col class="text-center" cols="12">
                  <router-link :to="{ name: 'services-terms-and-conditions' }">Általános Szerződési Feltételek</router-link>
                  - <router-link :to="{ name: 'privacy-policy' }">Adatvédelmi Szabályzat</router-link>
                  - <router-link :to="{ name: 'cookie-policy' }">Cookie szabályzat</router-link>
                  - <span @click="showCookieBanner()" class="footer-menu-item">Cookie beállítások</span>
                  <br/>
                  {{ new Date().getFullYear() }} —
                  <strong>Nyíltbankolás Zrt.</strong>
                </v-col>
              </v-row>
            </footer>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import "./_App.scss";
import Vue from 'vue';
import VpdToolbar from "./components/vpd-toolbar/vpd-toolbar";
import VpdCookieBanner from "./components/vpd-cookie-banner/vpd-cookie-banner";
import SocketService from "./services/socket.service.js";
import VpdEventBus from "./modules/vpd-event-bus";

export default {
  name: "App",
  components: { VpdToolbar, VpdCookieBanner },
  data:() => ({
    show: Vue.cookie.get("accepted") ? false : true,
    isOpten: false
  }),
  props: ["config"],
  async created() {
    const isOptenStored = localStorage.isOpten ? JSON.parse(localStorage.isOpten) : false;
    this.isOpten = this.$route.query.service !== undefined || isOptenStored;
    if (this.isOpten) {
      this.$vuetify.theme.themes.light.primary = '#0032BE';
      localStorage.setItem("isOpten", this.isOpten)
    } else {
      this.$vuetify.theme.themes.light.primary = '#e1337c';
    }
    await SocketService.setupSocketConnection();
    if (SocketService.getSocket() != null)
      SocketService.getSocket().addEventListener("message", (event) => {
        let eventObj = JSON.parse(event.data);

        switch (eventObj.eventType) {
          case "account":
            this.$notify({
              group: 'success',
              title: 'Értesítés',
              text: 'Friss bankszámla adatok érkeztek!'
            });
            VpdEventBus.$emit("refresh-account");
            break;
          case "transaction":
            this.$notify({
              group: 'success',
              title: 'Értesítés',
              text: 'Friss bankszámla adatok érkeztek!'
            });
            VpdEventBus.$emit("refresh-transaction");
            break;
          default:
            console.log("Ismeretlen esemény típus");
        }
      });
  },
  mounted() {
    window.addEventListener('opten-localstorage-changed', (event) => {
      this.isOpten = event.detail.storage === 'true' || this.$route.query.service !== undefined;
      if (this.isOpten) {
        this.$vuetify.theme.themes.light.primary = '#0032BE';
      } else {
        this.$vuetify.theme.themes.light.primary = '#e1337c';
      }
    });
  },
  methods: {
    hideCookieBanner() {
      this.show = false;
    },
    showCookieBanner() {
      this.show = true;
    } 
  },
};
</script>
