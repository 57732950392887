<template>
  <v-container
    class="vpd-content"
    v-if="!this.showConfirm"
    key="registration-state"
    fluid
  >
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-registration-panel
      @submit="submitRegistration"
      @federation="handleFederationSignIn"
      :config="config"
      :isOpten="isOpten"
      ref="registrationPanel"
    />
  </v-container>
  <v-container class="vpd-content" v-else key="registration-state">
    <vpd-notification-panel
      :titleText="confirmTitle"
      :bodyText="confirmText"
      :buttonRoute="buttonRoute"
      :buttonText="buttonText"
      buttonColor="primary"
      backButton="false"
    />
  </v-container>
</template>

<script>
import VpdRegistrationPanel from "../components/auth/vpd-registration-panel/vpd-registration-panel";
import VpdNotificationPanel from "../components/vpd-notification-panel/vpd-notification-panel";
import { signUp } from "../modules/auth";
import { camelCase } from "change-case";
import VpdEventBus from "../modules/vpd-event-bus";

const confirmTitle = "Regisztációs e-mail elküldve";
const confirmText =
  "Kérjük, ellenőrizze a postafiókját! A megadott emailcímre elküldtük a regisztráció befejezéséhez szükséges információkat.";
const buttonRoute = "/";
const buttonText = "Főoldal";
const errorMessages = {
  UsernameExistsException:
    "Ezzel az emailcímmel már létezik regisztrált felhasználó!",
  UserLambdaValidationException: "Hiba történt a szerveren!",
};

export default {
  name: "registration",
  props: ["config"],
  components: {
    VpdNotificationPanel,
    VpdRegistrationPanel,
  },
  data: () => ({
    overlay: false,
    showConfirm: false,
    confirmTitle: "",
    confirmText: "",
    buttonRoute: "",
    buttonText: "",
    isOpten: false,
  }),
  methods: {
    async submitRegistration(username, password, email) {
      const panel = this.$refs.registrationPanel;
      this.overlay = true;
      const status = await signUp(username, password, email, this.isOpten);
      this.overlay = false;
      document.getElementById('app').scrollIntoView({ behavior: 'auto' });
      if (status.code === "success") {
        this.showConfirm = true;
        return;
      }
      const handler = `${camelCase(status.code)}Handler`;
      if (typeof this[handler] === "function") {
        this[handler](panel);
      } else {
        panel.showAlert(`Sikertelen regisztráció!`, "error");
      }
    },
    usernameExistsExceptionHandler(panel) {
      panel.showAlert(errorMessages.UsernameExistsException, "error");
    },
    userLambdaValidationException(panel) {
      panel.showAlert(errorMessages.userLambdaValidationException, "error");
    },
    handleFederationSignIn(provider) {
      VpdEventBus.$emit("redirect", { name: "federated", payload: provider });
    },
  },
  created() {
    this.confirmTitle = confirmTitle;
    this.confirmText = confirmText;
    this.buttonRoute = buttonRoute;
    this.buttonText = buttonText;
    this.isOpten = this.$route.query.service !== undefined;
  },
};
</script>
