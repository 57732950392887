<template>
  <v-container class="vpd-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div  v-if="!isOpten">
      <vpd-hero-image></vpd-hero-image>
      <vpd-welcome-text
        showButton
        :dialog="dialog"
        :config="config"
        :account-count="accountCount"
        :isOpten="isOpten"
      />
    </div>
    <div class="login-view-container" v-if="isOpten">
      <vpd-welcome-text
        showButton
        :dialog="dialog"
        :config="config"
        :account-count="accountCount"
        :isOpten="isOpten"
      />
      <img
        class="dyc-image"
        alt="DYC logo."
        srcset="
          @/assets/dyc_footprint_white-small.png  220w,
          @/assets/dyc_footprint_white-normal.png  440w,
          @/assets/dyc_footprint_white-large.png  880w,
        "
      />
    </div>
    <v-tabs
      v-model="tab"
      class="vpd-tabs"
      color="grey"
      active-class="vpd-tabs-active"
      grow
      slider-color="transparent"
    >
      <v-tab v-for="item in items" :key="item.id" :to="item.route">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <div>
        <router-view></router-view>
      </div>
    </v-tabs-items>
  </v-container>
</template>

<script>
import "./_Home.scss";
import VpdWelcomeText from "../components/vpd-welcome-text/vpd-welcome-text";
import VpdHeroImage from "@/components/auth/vpd-hero-image/vpd-hero-image";

export default {
  data() {
    return {
      apiName: "RestApi",
      dialog: false,
      summaries: [],
      transactions: [],
      accounts: [],
      transactionsTotalCount: null,
      transactionsItemsPerPage: 5,
      lastEvaluatedAccountKey: null,
      transactionsLength: 1,
      tab: null,
      overlay: false,
      items: [
        { id: 1, name: "Csatolt bankszámlák", route: "accounts" },
        { id: 2, name: "Számlatörténet megosztása", route: "services" },
      ],
      isOpten: false,
    };
  },
  methods: {
    updateRouter(tab) {
      this.$router.push(tab);
    },
  },
  name: "home",
  components: { VpdHeroImage, VpdWelcomeText },
  computed: {
    accountCount() {
      return this.$store.state.account.count;
    },
  },
  props: ["config"],
  mounted() {
    window.addEventListener('opten-localstorage-changed', (event) => {
      this.isOpten = event.detail.storage === 'true';
    });
  },
  created() {
    this.isOpten = localStorage.isOpten ? JSON.parse(localStorage.isOpten) : false;
  },
};
</script>
