<template>
  <v-card-title class="vpd-card-title">
    <v-row class="py-0 vpd-card-title-row">
      <v-col cols="1" class="d-flex align-start px-0 py-0 vpd-card-title-col">
        <vpd-go-back v-if="hasBackButton"></vpd-go-back>
      </v-col>
      <v-col cols="10" class="px-0 py-0">
        <div class="headline d-flex justify-center align-center with-icon mt-0 pb-0">
          {{ title }}
        </div>
      </v-col>
      <v-col cols="1" class="px-0 py-0"> </v-col>
    </v-row>
  </v-card-title>
</template>

<script>
import "./_vpd-card-title.scss";
import VpdGoBack from "@/components/vpd-go-back/vpd-go-back";

export default {
  name: "vpd-card-title",
  components: { VpdGoBack },
  props: {
    title: {
      type: String,
      required: false,
    },
    hasBackButton: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped></style>
