<template>
  <v-list two-line>
    <v-list-item-group v-model="selected" multiple>
      <template v-for="(account, index) in accounts">
        <v-list-item :key="account.id" :disabled="account.connected">
          <v-list-item-content>
            <v-list-item-title>{{account.title}} - {{account.currency}}</v-list-item-title>
            <v-list-item-subtitle
              class="text--primary"
              v-text="account.headline"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="account.subtitle"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <div v-if="!account.connected">
            <v-list-item-action>
              <v-checkbox :input-value="selected" :value="index"></v-checkbox>
            </v-list-item-action>
          </div>
          <div v-else>
            <v-list-item-action class="d-flex flex-row justify-space-between">
              <span>Már csatlakoztatva</span>
              <v-checkbox
                disabled
                readonly
                input-value="true"
                class="ps-10"
              ></v-checkbox>
            </v-list-item-action>
          </div>
        </v-list-item>
        <v-divider v-if="index + 1 < accounts.length" :key="index"></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import "./_vpd-consent-account-panel.scss";

export default {
  data() {
    return {
      selected: [],
    };
  },
  name: "vpd-consent-account-panel",
  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },
};
</script>
