const actions = {
  panelState({ commit }, arg) {
    commit("panelState", arg);
  },
  userState({ commit }, state) {
    commit("userState", state);
  },
};

export default actions;
