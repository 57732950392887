<template>
  <v-dialog @click:outside="closeModal()" v-model="dialog" max-width="700">
    <v-card class="vpd-dialog">
      <v-card-title class="headline">
        {{ headline }}
      </v-card-title>
      <v-card-subtitle class="subtitle-1 pt-6">
        {{ subtitle }}
      </v-card-subtitle>
        <v-row v-if="bankId && schema" justify="center" >
          <v-col  cols="12" class="pa-0 col-dense d-flex justify-center">
              <FormulateForm
                    name="bankForm"
                    v-model="values"
                    :schema="schema"
                    @validation="validation = $event"
                    @blur="displayFormInputFields"
                    @submit="handleSubmit(event, values)"
                    >

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" class="secondary uppercase v-btn--has-bg theme--light" text>
                    Igen
                  </v-btn>
                  <v-btn class="secondary uppercase" text @click="closeModal()">
                    Mégsem
                  </v-btn>
                </v-card-actions>
              </FormulateForm>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      <v-card-actions v-if="this.schema == null">
        <v-spacer></v-spacer>
        <v-btn class="secondary uppercase v-btn--has-bg theme--light" text @click="$emit(event, values)">
          Igen
        </v-btn>
        <v-btn class="secondary uppercase" text @click="$emit('close-modal')">
          Mégsem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "./_vpd-confirm-dialog.scss";

export default {
  name: "vpd-confirm-dialog",
  methods: {
    displayFormInputFields() {

      if (this.values?.iban) {
        this.values.iban = this.values.iban.replace(/[^0-9]/g, '');
        
        if (this.values.iban?.length == 16) {
          this.values.iban = this.values.iban.substring(0,8) + "-" + this.values.iban.substring(8) + "-00000000";
        }

        if (this.values.iban?.length == 24) {
          this.values.iban = this.values.iban.substring(0,8) + "-" + this.values.iban.substring(8,16) + "-" + this.values.iban.substring(16);
        }
      }

      if (this.values?.currency) {
        this.values.currency = this.values.currency.toUpperCase();
      }
    },
    handleSubmit(event, values) {
      this.$emit(event, values);
      this.$formulate.reset('bankForm');
    },
    closeModal() {
      if (this.schema !== null) {
        this.$formulate.reset('bankForm');
      }
      this.$emit('close-modal');
    }
  },
  props: {
    headline: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    event: {
      type: String,
      required: true,
    },
    bankId: {
      type: String,
      required: false,
    },
    schema: {
      type: Array,
      required: false,
    }
  },
  data: function() {
    return {
      values: {},
      validation: {},
    }
  },
};
</script>
