import axiosService from "@/modules/axios-service";

class ServicesService {
  path = "/services";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async fetchServices() {
    return await axiosService.get(this.path);
  }

  async addServiceToAccounts(route, body) {
    return await axiosService.post(this.path + route, body).then(() => {
      return true;
    });
  }

  async removeAccountFromService(selectedServiceId, selectedAccountId) {
    const path =
      this.path +
      "/" +
      selectedServiceId +
      "/accounts/" +
      selectedAccountId.replaceAll("#", "%23");

    await axiosService.delete(path);
  }

  async generateTokenForCashbook(body) {
    const path =
      this.path +
      "/token/create";

    return await axiosService.post(path, body).then((promise) => {
      return promise;
    });
  }

  async confirmSsoTokenForCashbook(ssoToken) {
    const path =
      this.path +
      "/token/confirm?sso=" + ssoToken;

      console.log(path)
    await axiosService.get(path);
  }
}

export default new ServicesService();
