<template>
  <v-container class="vpd-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card class="vpd-panel">
      <vpd-card-title
        title="Számlatörténet megosztása"
        has-back-button
      ></vpd-card-title>
      <vpd-available-services-panel
        v-if="
          services[0] &&
          (!$vuetify.breakpoint.mobile ||
            ($vuetify.breakpoint.mobile && !selectedServiceId))
        "
        :services="services"
        v-on:selected-service-changed="showAvailableAccounts"
      >
      </vpd-available-services-panel>

      <vpd-available-accounts-panel
        :accounts="accounts"
        :selectedServiceId="selectedServiceId"
        ref="accountpanel"
        v-on:selected-accounts-changed="setNextButtonVisibility"
      >
      </vpd-available-accounts-panel>

      <v-row justify="center" class="pt-10">
        <v-col cols="12" align="center" v-if="accounts[0]">
          <v-btn
            class="primary fix-width"
            type="submit"
            v-if="this.selectedServiceId && this.showNextButton"
            @click.prevent="submit"
            >Tovább
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
//import VpdSummary from "../components/vpd-summary/vpd-summary";
import VpdAvailableServicesPanel from "../components/vpd-available-services-panel/vpd-available-services-panel";
import VpdAvailableAccountsPanel from "../components/vpd-available-accounts-panel/vpd-available-accounts-panel";
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
import accountsService from "@/services/accounts-service";
import servicesService from "@/services/services-service";

export default {
  name: "services",
  props: ["config"],
  data() {
    return {
      overlay: false,
      accounts: [],
      loadingTransactions: false,
      loadingAccounts: false,
      showNextButton: false,
      summaries: [],
      services: [],
      selected: [],
      selectedServiceId: "",
    };
  },
  components: {
    VpdCardTitle,
    //VpdSummary,
    VpdAvailableServicesPanel,
    VpdAvailableAccountsPanel,
  },
  methods: {
    async showAvailableAccounts(payload) {
      this.selectedServiceId = payload.serviceId;
      this.overlay = true;
      const accountsData = await accountsService.fetchAccountsForService();
      if (accountsData) this.loadAccountsData(accountsData);
      this.loadingAccounts = false;
      this.overlay = false;
    },
    loadAccountsData: function (data) {
      let selectedServiceId = this.selectedServiceId;
      data.items.some(function (account) {
        account.alreadyConnected = !!account.connectedServices.some(
          (service) => service.id === selectedServiceId
        );
      });
      this.accounts = data.items.filter(account => account.isValid.toLowerCase() === "true");
    },
    setNextButtonVisibility(payload) {
      this.showNextButton = payload.length > 0;
    },
    getSelectedAccounts(selected) {
      const array = [];
      selected.forEach((value) => {
        array.push(this.accounts[value]);
      });
      return array;
    },
    redirectToSummaryPage() {
      this.$router.push({ name: "add-services-summary" });
    },
    redirectToCashbook(token) {
      if(window.location.href.includes("demoportal")){
        window.location = "https://cashbook.io/auth/sso/nyiltbankolas/" + token;
      }else{
        window.location = "https://cashbook.hu/auth/sso/nyiltbankolas/" + token;
      }
    },
    async submit() {
      const selected = this.$refs.accountpanel.selected;
      if (selected.length === 0) {
        localStorage.removeItem("selectedServiceId");
        localStorage.removeItem("selectedAccounts");
        //TODO hibakezelés
        return;
      }
      const accounts = this.getSelectedAccounts(selected);
      const services = this.services;

      localStorage.selectedServiceId = JSON.stringify(
        services.find((service) => service.id === this.selectedServiceId)
      );
      localStorage.selectedAccounts = JSON.stringify(accounts);

      // Cashbook serviceId
      if (this.selectedServiceId === '2e357456-506c-429e-92e7-92551f27f2f1') {
        const result = await servicesService.generateTokenForCashbook({
          'accountIds': accounts.map(account => account.id),
          'serviceId': this.selectedServiceId 
        });
        this.redirectToCashbook(result.token);
      } else {
        this.redirectToSummaryPage();
      }
    },
  },
  async created() {
    this.overlay = true;
    localStorage.removeItem("selectedServiceId");
    localStorage.removeItem("selectedAccounts");
    this.services = await servicesService.fetchServices();
    // Filter out Leanpay
    this.services = this.services.filter(service => service.id !== "3cb23ecf-a2c8-4193-b441-0f1945d9ccb1")
   
    this.overlay = false;
  },
};
</script>
