<template>
  <v-card class="vpd-panel vpd-login-panel">
    <v-card-title class="headline d-flex justify-center"
      >Bejelentkezés vagy Regisztráció</v-card-title
    >
    <v-alert
      v-model="alert"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertMessage }}
    </v-alert>
    <!--v-form v-model="valid" lazy-validation @submit.prevent="submit" ref="form"-->
    <v-form v-model="valid" ref="form" autocomplete="on">
      <v-row justify="center">
        <v-col align="center" class="pl-0 pr-0">
          <v-btn class="facebook huge fix-width" @click="authFbPlain">
            <div class="d-flex justify-space-between">
              <span class="align-center ">
                <img class="facebook-login-img" :src="getImgUrl('facebook')"/>
                </span>
              <v-spacer />
              <span class="d-flex align-center center-with-icon"
                >Bejelentkezés Facebookkal</span
              >
              <v-spacer />
            </div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pl-0 pr-0">
          <v-btn class="google huge fix-width" @click="authGooglePlain">
            <div class="d-flex justify-space-between">
              <span class="align-center">
                <img class="google-login-img" :src="getImgUrl('google')" />
              </span>
              <v-spacer />
              <span class="d-flex align-center center-with-icon"
                >Bejelentkezés Google fiókkal
              </span>
              <v-spacer />
            </div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-1 pb-2">
          <v-text-field
            label="Email cím"
            v-model="email"
            :rules="emailRules"
            hide-details="auto"
            outlined
            dense
            height="50"
            autocomplete="email"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-1 pb-2">
          <v-text-field
            label="Jelszó"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            hide-details="auto"
            hint="Min.8 karakter"
            @click:append="showPassword = !showPassword"
            :rules="passwordRules"
            outlined
            dense
            height="50"
            autocomplete="current-password"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-1 pl-0 pr-0">
          <v-btn
            class="primary huge fix-width"
            type="submit"
            @click.prevent="submit"
            >Bejelentkezés Email címmel
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-2 pb-2 pl-0 pr-0">
          <a @click="$emit('reset-pass')" class="vpd-login-panel__forgotten"
            >Elfelejtett jelszó?</a
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pl-0 pr-0">
          <v-btn class="secondary huge fix-width" @click="$emit('signup')"
            >Nincs még profilja? Regisztráljon itt!</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <div class="empty-divider"></div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import "./_vpd-login-panel.scss";
import { Auth } from "aws-amplify";
import { getImgUrl } from "@/modules/helpers";

export default {
  data: () => ({
    alert: false,
    alertMessage: "",
    alertType: "error",
    valid: false,
    password: "",
    showPassword: false,
    email: "",
    emailRules: [
      (v) => !!v || "Az email cím kötelező",
      (v) => /.+@.+/.test(v) || "Érvénytelen email",
    ],
    passwordRules: [
      (v) => !!v || "Hiányzó jelszó",
      (v) => (v && v.length >= 8) || "A jelszó min. 8 karakter",
    ],
  }),
  methods: {
    checkUser() {
      Auth.currentAuthenticatedUser()
        .then((user) => (console.log()))
        .catch((err) => console.log(err));
    },
    submit() {
      //TODO remove this.$refs
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.email, this.password);
      }
    },
    showAlert(msg, type) {
      this.alertMessage = msg;
      this.alertType = type;
      this.alert = true;
    },
    authGooglePlain() {
      Auth.federatedSignIn({ provider: "Google" });
    },
    authFbPlain() {
      Auth.federatedSignIn({ provider: "Facebook" });
    },
    getImgUrl(name) {
      return getImgUrl(name);
    },
  },
};
</script>
