<template>
  <v-card class="vpd-panel vpd-profile-panel">
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-card-title title="Profil"></vpd-card-title>
    <v-alert
      v-model="alert"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertMessage }}
    </v-alert>
    <v-row justify="center">
      <v-col align="center" class="pb-2">
        <div class="profil-label fix-width left">Név: {{ username }}</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align="center" class="pb-6">
        <div class="profil-label fix-width left">Email: {{ email }}</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align="center" class="pb-10">
        <div class="description fix-width left">
          A regisztrációkor megadott nevet és emailcímet biztonsági okokból nem lehet megváltoztatatni.
        </div>
        <div>
          Ha új emailcímet szeretne használni, azt a profilja törlésével majd új regisztráció létrehozásával teheti meg.
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align="center">
        <v-btn
          class="primary huge fix-width"
          @click="redirectToChangePasswordPage"
          >Jelszó megváltoztatása
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align="center" class="pt-10">
        <v-btn @click="wipeProfileInit" class="error fix-width huge"
          >Profil törlése
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import "./_vpd-profile-panel.scss";
import { Auth } from "aws-amplify";
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
import userService from "@/services/user-service";

export default {
  name: "vpd-profile-panel",
  components: { VpdCardTitle },
  props: {},
  data: () => ({
    alert: false,
    alertMessage: "",
    alertType: "error",
    valid: false,
    overlay: true,
    username: null,
    userId: null,
    email: null,
    dateCreated: null,
    profilePicture: false,
  }),
  methods: {
    async federationConfig(data) {
      var identities = JSON.parse(data.attributes.identities)[0];
      if (identities.providerType == "Facebook") {
        this.federationLogin = true;
        this.profilePicture = JSON.parse(data.attributes.picture).data.url;
      }
      if (identities.providerType == "Google") {
        this.federationLogin = true;
        this.profilePicture = data.attributes.picture;
      }
    },
    routeToHome() {
      return this.$router.push("/");
    },
    redirectToChangePasswordPage() {
      this.$router.push({ name: "change-password" });
    },
    setGooglePicture() {},
    setFacebookPicture() {},
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.oldPassword, this.newPassword);
      }
    },
    async wipeProfileInit() {
      this.overlay = true;

      const result = await userService.wipeProfileInit();
      if (result) {
        this.alert = result.alert;
        this.alertType = result.alertType;
        this.alertMessage = result.alertMessage;
      }

      this.overlay = false;
    },
  },
  created() {
    Auth.currentUserInfo()
      .then((data) => {
        this.username = data.attributes.name;
        this.userId = data.username;
        this.email = data.attributes.email;
        if (data.attributes.identities != undefined) {
          this.federationConfig(data);
        } else {
          this.showChangePwForm = true;
        }
        this.overlay = false;
      })
      .catch((err) => {
        this.alertMessage = "Hiba történt a szerveren!";
        this.overlay = false;
        this.alertType = "error";
        this.alert = true;
      });
  },
};
</script>
