<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card>
      <v-card-title class="headline">
        {{ headline }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" text @click="$emit(event)"> {{ buttonText ? buttonText : "Rendben" }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "vpd-alert-dialog",
  props: {
    headline: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    event: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false
    }
  },
};
</script>
