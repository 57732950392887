<template>
  <div class="vpd-cookie-banner-message">
    <strong>Ez a weboldal cookie-kat (magyarul: sütiket) használ</strong>
    <br>
    A sütik olyan kis méretű adatcsomagok, amelyeket az internetes szolgáltatások a böngészőben tárolnak el.
    A sütik alkalmazása számos célból történik, vannak amelyek az oldal optimális működéséhez elengedhetetlenek,
    mások a felhasználói élmény fokozásához, illetve célzott hirdetések megjelenítéséhez szükségesek,
    vannak továbbá statisztikai célokat szolgáló sütik is.
    A sütikre vonatkozó részletesebb információt <router-link :to="{ name: 'cookie-policy' }"><strong>"Cookie szabályzat"</strong></router-link> oldalunkon talál.
    A sütik alkalmazhatóságát az Ön gépén a "Beállítások" feliratra kattintva tudja módosítani.
    Abban az esetben, ha hozzájárul a sütik használatához, kattintson az "Elfogadás" gombra.
  </div>
</template>

<script>
import "./_vpd-cookie-banner-message.scss";

export default {
};
</script>
