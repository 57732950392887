import axiosService from "@/modules/axios-service";

class AccountService {
  path = "/accounts";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async fetchAccounts() {
    return await axiosService.get(this.path);
  }

  async fetchAccountsForService() {
    return await axiosService.get(this.path);
  }

  async fetchAccountsForConsent(route) {
    return await axiosService.get(route + this.path);
  }

  async refreshAccounts() {
    await axiosService.post(this.path + '/refresh');
  }
}

export default new AccountService();
