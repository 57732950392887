<template>
  <v-card class="vpd-md-viewer">
    <vpd-card-title
        v-if="title"
        :title="title"
        has-back-button
    ></vpd-card-title>
    <br>
    <vpd-markdown :source="source"></vpd-markdown>
  </v-card>
</template>

<script>
import "./_vpd-md-viewer.scss";
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
import VpdMarkdown from "@/components/vpd-markdown/vpd-markdown";

export default {
  name: "vpd-md-viewer",
  props: {
    title: {
      type: String,
      required: false,
    },
    source: {
      type: String,
      required: false,
    },
  },
  components: { VpdCardTitle, VpdMarkdown }
};
</script>
