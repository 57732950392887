<template>
  <v-card class="vpd-panel vpd-resetpass-panel">
    <vpd-card-title title="Elfelejtett jelszó" has-back-button></vpd-card-title>
    <v-alert
      v-model="alert"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertMessage }}</v-alert
    >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-col align="center" class="subtitle-1">
          A jelszó visszaállításához adja meg a regisztrációhoz használt email
          címét
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <v-text-field
            label="Email cím"
            :rules="emailRules"
            outlined
            v-model="email"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <v-btn class="primary fix-width" @click="resetPass()">{{
            "Visszaállító link küldése"
          }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import "./_vpd-resetpass-panel.scss";
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";

export default {
  name: "vpd-resetpass-panel",
  components: { VpdCardTitle },
  props: {},
  data: () => ({
    alert: false,
    alertMessage: "",
    alertType: "error",
    email: "",
    valid: false,
    emailRules: [
      (v) => !!v || "Az email cím kötelező",
      (v) => /.+@.+/.test(v) || "Érvénytelen email",
    ],
  }),
  methods: {
    resetPass() {
      //TODO remove this.$refs
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.email);
      }
    },
    showAlert(msg, type) {
      this.alertMessage = msg;
      this.alertType = type;
      this.alert = true;
    },
  },
};
</script>
