<template>
  <img
    class="hero-image"
    alt="Banking hero image."
    srcset="
      @/assets/hero-image-small.png  220w,
      @/assets/hero-image-normal.png 440w,
      @/assets/hero-image-large.png  880w
    "
  />
</template>

<script>
export default {
  name: "vpd-hero-image",
};
</script>

<style scoped></style>
