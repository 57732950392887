<template>
  <v-container class="vpd-service-overview-panel">
    <v-row align="center" justify="space-between" class="pa-6">
      <v-col cols="12">
        <div class="vpd-account-panel__type d-flex flex-row">
          <div
            class="
              row
              justify-start
              d-flex
              flex-row
              align-content-start align-center
            "
          >
            <div class="img-container">
              <img :src="getServiceImgUrl(service)" />
            </div>
            <div class="ms-3 d-flex flex-column">
              <div v-if="service.name" class="subtitle-1">
                {{ service.name }}
              </div>
              <div class="caption">
                <vpd-markdown :source="service.shortDescription"></vpd-markdown>
              </div>
             
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-between" class="pa-6">
      <v-col cols="12">
        <div class="vpd-account-panel__type">
          <div class="row justify-start align-content-start">
            <!-- <div class="img-container"></div> -->
            <div class="ms-3">
              <vpd-markdown :source="service.description"></vpd-markdown>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
        <div v-if="service.isEntityIdNeeded">
          <v-row class="vpd-service-overview-panel__label">
            <label class="formulate-input-label formulate-input-label--before">
                {{"Kérjük adja meg a(z) "+ this.service.name + " oldalán kapott kódot:"}}
            </label>
          </v-row>
          <v-row justify="center" class="mt-3" >
            <v-col cols="12" class="pa-0 col-dense d-flex vpd-service-overview-panel__row">
                <FormulateForm                      
                      class="entityIdForm"
                      v-model="values"
                      :schema="schema"
                      @validation="validation = $event"
                      @submit="handleSubmit"
                      >
                  <v-row justify="center">
                    <v-col cols="12" align="center">
                      <v-btn :disabled="this.validation.hasErrors"
                        class="primary mx-auto d-none d-lg-flex margin-bottom"
                        type="submit"
                        >Validálás
                      </v-btn>
                      <v-btn :disabled="this.validation.hasErrors"
                        class="primary fix-width d-sm-flex d-lg-none"
                        type="submit"
                        >Validálás
                      </v-btn>
                    </v-col>
                  </v-row>
                </FormulateForm>
            </v-col>
          </v-row>
          <v-row class="vpd-service-overview-panel__row">
            <ul class="formulate-input-errors">
              <li v-if="this.validation.hasErrors && this.values.entityId == ''" class="error-message">
                  A kód megadása kötelező!
              </li>
            </ul>
          </v-row>
                    <v-container v-if="codeIsValidated && this.service.id != 'f8756a3f-badf-4bc2-9681-5a8ab5215195'" class="mt-2">
            <v-row class="vpd-account-panel__confirmation-text">
              <v-col cols="auto" class="pa-0 col-dense d-flex justify-center">
                  Amennyiben folytatja, a bankszámla adatait az alábbi {{this.service.name}} céges fiókjához rendeljük hozzá:
              </v-col>
            </v-row>
            <v-row class="vpd-account-panel__company mt-1">
              <v-col cols="auto" class="pa-0 col-dense d-flex justify-center">
                  Cégnév: <span class="font-weight-bold ml-1">{{this.company}}</span>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="codeIsValidated && this.service.id == 'f8756a3f-badf-4bc2-9681-5a8ab5215195'" class="mt-2">
            <v-row class="vpd-account-panel__confirmation-text">
              <v-col cols="auto" class="pa-0 col-dense d-flex justify-center">
                  Amennyiben folytatja, a bankszámla adatait az alábbi {{this.service.name}} fiókjához rendeljük hozzá:
              </v-col>
            </v-row>
            <v-row class="vpd-account-panel__company mt-1">
              <v-col cols="auto" class="pa-0 col-dense d-flex justify-center">
                  Előfizetési név: <span class="font-weight-bold ml-1">{{this.company}}</span>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-if="service.isMultipleEmailNeeded">
          <v-row class="vpd-service-overview-panel__label">
            <label class="formulate-input-label formulate-input-label--before">
                {{"Kérjük adja meg a(z) "+ this.service.name + " által kapott email címet:"}}
            </label>
          </v-row>
          <v-row justify="center" class="mt-3" >
            <v-col cols="12" class="pa-0 col-dense d-flex vpd-service-overview-panel__row">
                <input class="input-border" type="text" v-on:input="updateEmail($event.target.value, khEmailEnding)">
                <div class="ml-1">{{ this.khEmailEnding }}</div>
            </v-col>
          </v-row>
           <v-row class="vpd-service-overview-panel__row">
            <ul class="formulate-input-errors">
              <li v-if="this.emailAddress === this.khEmailEnding" class="error-message">
                  Az email cím megadása kötelező!
              </li>
              <li v-if="!(this.emailAddress === this.khEmailEnding) && !isEmailValid" class="error-message">
                  Az email cím érvénytelen!
              </li>
            </ul>
          </v-row>
        </div>
  </v-container>
</template>

<script>
import "./_vpd-service-overview-panel.scss";
import { getServiceImgUrl } from "@/modules/helpers.js";
import VpdMarkdown from "@/components/vpd-markdown/vpd-markdown";
import partnerAttachmentService from "@/services/partner-attachment-service";

export default {
  name: "vpd-service-overview-panel",
  props: {
    service: {
      type: Object,
      required: true,
    },
    codeIsValidated: {
      type: Boolean,
      required: true,
    },
    emailAddress: {
      type: String,
    },
    khEmailEnding: {
      type: String,
    },
  },
    data () {
    return {
      values: {},
      validation: {},
      schema: [
      {
        "type": "text",
        "validation": "required",
        "validation-messages": {"required": "A kód megadása kötelező!"},
        "disableErrors": true,
        "name": "entityId",
      }],
      company: "",
      isEmailValid: true
    }
  },
  components: { VpdMarkdown },
  methods: {
    getServiceImgUrl(service) {
      return getServiceImgUrl(service.id);
    },
    //Reformat mock markdown- should remove in production
    cleanMarkDown(markDownText) {
      return markDownText
        .replaceAll("          ", "")
        .replaceAll("# ", "#### ");
    },
    handleSubmit() {
      const requestBody = {
        tokenValue: this.values.entityId,
        partnerId: this.service.id
      }

      partnerAttachmentService.validateEntityId(requestBody).then((resp) => {
        this.company = resp.partnerName;
        this.$emit("setcodeIsValidated");
        this.$emit("setEntityId", this.values.entityId)
      });
    },
    updateEmail: function (value, khEmailEnding) {
      const email = value + khEmailEnding;
      const result = email.toLowerCase().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      this.isEmailValid = result !== null;
      if (this.isEmailValid) {
        this.$emit("inputChange", value + khEmailEnding);
      }
    }
  },
};
</script>
