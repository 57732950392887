import axiosService from "@/modules/axios-service";

class ConsentsService {
  path = "/consents";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async submit(params) {
    return await axiosService.post(this.path, params);
  }
}

export default new ConsentsService();
