<template>
  <v-container class="vpd-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-change-password-panel
      @submit="submitPasswordChange"
      ref="changePasswordPanel"
    />
  </v-container>
</template>

<script>
/*import { camelCase } from "change-case";*/
//import VpdEventBus from "../modules/vpd-event-bus";
import { Auth } from "aws-amplify";
import VpdChangePasswordPanel from "@/components/auth/vpd-change-password-panel/vpd-change-password-panel";

const errorMessages = {
  success: "Sikeres módosítás!",
  UserLambdaValidationException: "Kiszolgáló hiba!",
};

export default {
  name: "change-password",
  props: ["config"],
  components: {
    VpdChangePasswordPanel,
  },
  data: () => ({
    overlay: false,
  }),
  methods: {
    async submitPasswordChange(oldPassword, newPassword) {
      const user = await Auth.currentAuthenticatedUser();
      const panel = this.$refs.changePasswordPanel;
      Auth.changePassword(user, oldPassword, newPassword)
        .then((data) => {
          panel.alertType = "success";
          panel.alert = false; // Eltüntetjük, ha korábban valamiért megjelent
          panel.oldPassword = null;
          panel.newPassword = null;
          panel.newPasswordRe = null;
          panel.showForm = false;
          panel.showBackButton = true;
        })
        .catch((err) => {
          panel.alertMessage = err.message;
          panel.alert = true;
        });
    },
    async userLambdaValidationException(panel, email, status) {
      panel.showAlert(`${errorMessages[status.code]}: ${status.message}`);
    },
  },
};
</script>
