import axios from "axios";
import { Auth } from "aws-amplify";
import awsconfig from "@/aws-exports";
import Vue from 'vue'

const axiosService = axios.create({
  timeout: 120000
});

// Add a request interceptor
axiosService.interceptors.request.use(
  async function(config) {
    const userPoolUserToken = (await Auth.currentUserPoolUser()).signInUserSession.getAccessToken()
    .getJwtToken()

    config.headers = {
      Authorization: `Bearer ${userPoolUserToken}`,
      "Content-Type": "application/json"
    };
    config.baseURL = awsconfig.API.endpoints[0].endpoint;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosService.interceptors.response.use(
  response => {
    // Do something with response data
    return response.data;
  },
  error => {
    switch (error.response.status) {
      case 400:
        Vue.notify({
          group: 'error',
          title: "Hibás lekérdezés!",
          text: "A kért adatok lekérdezése közben hiba történt."
        })
        break;
      case 401:
        Vue.notify({
          group: 'error',
          title: "Jogosulatlan felhasználó!",
          text: "Nem rendelkezik érvényes hitelesítési adatokkal."
        })
        break;
      case 403:
        Vue.notify({
          group: 'error',
          title:"Tiltott lekérdezés!",
          text: "Nem rendelkezik érvényes hitelesítési adatokkal."
        })
        break;
      case 404:
        Vue.notify({
          group: 'error',
          title: "Nem található!",
          text: "A kért oldal nem található."
        })
        break;
      case 500:
        Vue.notify({
          group: 'error',
          title: "Nem érkeztek friss számlainformációk!",
          text: "Kérjük próbálkozzon újra később."
        })
    }
  }
);

export default axiosService;
