import axiosService from "@/modules/axios-service";

class UserService {
  path = "/user";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async wipeProfileInit() {
    return await axiosService.post(this.path + "/deleterequests").then(() => {
      return {
        alert: true,
        alertType: "success",
        alertMessage:
          "Email elküldve! Profilja végleges törléséhez elküldtük emailben a további információkat.",
      };
    });
  }
}

export default new UserService();
