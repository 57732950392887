<template>
  <v-container
    class="vpd-content"
    v-if="!this.showResult"
    key="confirm-state"
    fluid
  >
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-confirm-newpass-panel
      @submit="submitConfirm"
      ref="confirmNewpassPanel"
    >
    </vpd-confirm-newpass-panel>
  </v-container>
  <v-container class="vpd-content" v-else key="confirm-state">
    <vpd-notification-panel
      :alertText="alertText"
      alertType="success"
      buttonRoute="/"
      buttonText="Vissza a főoldalra"
    />
  </v-container>
</template>

<script>
import VpdConfirmNewpassPanel from "../components/auth/vpd-confirm-newpass-panel/vpd-confirm-newpass-panel";
import VpdNotificationPanel from "../components/vpd-notification-panel/vpd-notification-panel";
import { Auth } from "aws-amplify";

const alertText =
  "A jelszó módosításra került! Kérjük lépjen vissza a főoldalra és jelentkezzen be.";
const INVALID_CODE = "Érvénytelen vagy lejárt hitelesítő kód!";

export default {
  name: "confirm-new-pass",
  props: ["config"],
  components: {
    VpdConfirmNewpassPanel,
    VpdNotificationPanel,
  },
  data: () => ({
    showResult: false,
    overlay: false,
    alertText: "",
  }),
  methods: {
    async submitConfirm(password) {
      const panel = this.$refs.confirmNewpassPanel;
      this.overlay = true;
      const { user, code } = this.$route.query;
      Auth.forgotPasswordSubmit(user.toString(), code.toString(), password)
        .then(() => {
          this.showResult = true;
          this.overlay = false;
        })
        .catch(() => {
          panel.showAlert(INVALID_CODE, "error");
          this.overlay = false;
        });
    },
  },
  created() {
    this.alertText = alertText;
  },
};
</script>
