<template>
  <div class="vpd-cookie-banner-settings">
    <v-container class="vpd-cookie-banner-settings-button">
        <v-row align="center">
        <input class="input" disabled type="checkbox" :checked="enableNecessaryCookies" :value="{enableNecessaryCookies}" @change="updateNecessaryCookie()">
          <div class="vpd-cookie-banner-input-text" @click="setNecessaryCookiesMessage">Szükséges</div>
        </v-row>
        <v-row align="center">
        <input class="input" type="checkbox" :checked="enableAnalyticCookies" :value="{enableAnalyticCookies}" @change="updateAnalyticCookie()">
          <div class="vpd-cookie-banner-input-text" @click="setAnalyticCookiesMessage">Statisztikai</div>
        </v-row>
    </v-container>
    <v-container class="vpd-cookie-banner-settings-message">
      {{message}}
    </v-container>
  </div>
</template>

<script>
import "./_vpd-cookie-banner-settings.scss";

export default {
  props: ["enableNecessaryCookies", "enableAnalyticCookies"],
  data () {
      return {
        message: "A szükséges sütik segítenek használhatóvá tenni a weboldalunkat azáltal, " +
                  "hogy engedélyeznek olyan alapvető funkciókat, mint az oldalon való navigáció és a weboldal" +
                  " biztonságos területeihez való hozzáférés. A weboldal ezen sütik nélkül nem tud megfelelően működni.",
      }
    },
  methods: {
    setNecessaryCookiesMessage() {
      this.message = "A szükséges sütik segítenek használhatóvá tenni a weboldalunkat azáltal, " +
                      "hogy engedélyeznek olyan alapvető funkciókat, mint az oldalon való navigáció és a weboldal" +
                      " biztonságos területeihez való hozzáférés. A weboldal ezen sütik nélkül nem tud megfelelően működni."
    },
    setAnalyticCookiesMessage() {
      this.message = "Az adatok névtelen formában való gyűjtésén és jelentésén keresztül a statisztikai célú sütik segítenek" +
                      " a weboldal tulajdonosának abban, hogy megértse, hogyan lépnek interakcióba a látogatók a weboldallal."
    },
    updateNecessaryCookie() {
      this.$emit("updateNecessaryCookie")
    },
    updateAnalyticCookie() {
      this.$emit("updateAnalyticCookie")
    },
  },
};
</script>
