<template>
  <v-container class="vpd-account-simple-panel">
    <v-row align="center" justify="space-between" class="pa-6">
      <v-col cols="12">
        <div class="vpd-account-panel__type">
          <div
            class="
              justify-start
              align-content-start
              flex-row
              d-flex
              align-center
              pt-0
            "
          >
            <div class="img-container">
              <img :src="getBankMiniImgUrl(account.bank)" />
            </div>
            <div class="ms-3 d-flex flex-column align-content-start">
              <div v-if="account.name" class="subtitle-1">
                {{ account.name }} - {{ account.currency }}
              </div>
              <div
                class="caption"
                v-if="!account.expired"
                key="transactions-attached-state"
              >
                A tranzakciók lekérdezve
                <span
                  v-if="account.synchronizedFrom"
                  class="font-weight-bold"
                  >{{ diffInDays(account.synchronizedFrom) }}</span
                >
                napra visszamenőleg
              </div>
              <div class="caption" v-else key="transactions-attached-state">
                A tranzakciók
                <span v-if="account.expireAt" class="font-weight-bold">{{
                  since(account.expireAt)
                }}</span>
                nem kerülnek lekérdezésre
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "./_vpd-account-simple-panel.scss";
import moment from "moment";
import { getFormattedAmountValue, getBankImgUrl, getBankMiniImgUrl } from "@/modules/helpers";

export default {
  name: "vpd-account-simple-panel",
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  methods: {
    diffInDays(date) {
      var now = moment();
      return now.diff(date, "days");
    },
    since(date) {
      moment.locale("hu");
      return moment(Date.parse(date)).fromNow();
    },
    getBankImgUrl(bank) {
      return getBankImgUrl(bank);
    },
    getBankMiniImgUrl(bank) {
      return getBankMiniImgUrl(bank);
    },
    getNiceValue(value, currency) {
      return getFormattedAmountValue(value, currency);
    },
    getFormattedAccountNumber: function (accountNumber) {
      let formattedAccountNumber =
        accountNumber.substring(0, 8) + "-" + accountNumber.substring(16, 8);
      if (accountNumber.length > 16) {
        formattedAccountNumber += "-" + accountNumber.substring(24, 16);
      }
      return formattedAccountNumber;
    },
    moment: function () {
      return moment();
    },
    addDays(date, day) {
      return moment(date, "YYYY-MM-DD").add(day, "days");
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>
