import axios from "axios";
import awsconfig from "@/aws-exports";

class ActivationService {
  path = awsconfig.API.endpoints[0].endpoint + "/registration/confirm";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async activate(query) {
    const { user, code } = query;
    const SUCCES_MESSAGE =
      "Regisztrációja sikeresen megerősítésre került!";

    return await axios
      .get(this.path, {
        params: { user: user, code: code },
      })
      .then((data) => {
        if (data.data.success) {
          return {
            alertType: "success",
            alertMessage: SUCCES_MESSAGE,
          };
        }
        if (!data.data.success) {
          return {
            alertType: "error",
            alertMessage: data.data.message,
          };
        }
      });
  }
}

export default new ActivationService();
