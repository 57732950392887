<template>
  <div class="vpd-available-services-panel">
    <span class="subtitle-1">
      Kérjük válassza ki, hogy kivel szeretné megosztani a bankszámlatörténetét!
    </span>
    <v-list two-line>
      <v-radio-group v-model="selectedItem">
        <template v-for="(service, index) in services">
          <v-list-item :key="service.Id" @click="selectItem(service.id)">
            <v-list-item-avatar tile>
              <img v-if="isSelected(service)" class="img-size" :src="getServiceImgUrl(service)" />
              <img v-else class="img-size not-selected" :src="getServiceImgUrl(service)" />
            </v-list-item-avatar>
            <v-list-item-content class="d-flex align-center">
              <v-list-item-title v-text="service.name"></v-list-item-title>
              <div class="caption">
                <vpd-markdown :source="service.shortDescription"></vpd-markdown>
              </div>
            </v-list-item-content>
            <div>
              <v-list-item-action class="mr-0">
                <v-radio
                  v-if="!$vuetify.breakpoint.mobile"
                  :key="service.id"
                  :value="service.id"
                  color="secondary"
                ></v-radio>
                <vpd-chevron-right v-else></vpd-chevron-right>
              </v-list-item-action>
            </div>
          </v-list-item>
          <v-divider v-if="index + 1 < service.length" :key="index"></v-divider>
        </template>
      </v-radio-group>
    </v-list>
  </div>
</template>

<script>
import "./_vpd-available-services-panel.scss";
import { getServiceImgUrl } from "@/modules/helpers";
import VpdChevronRight from "@/components/icons/VpdChevronRight";
import VpdMarkdown from "@/components/vpd-markdown/vpd-markdown";

export default {
  data() {
    return {
      selectedItem: "",
    };
  },
  name: "vpd-available-services-panel",
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  components: { VpdChevronRight, VpdMarkdown },
  methods: {
    getServiceImgUrl(service) {
      return getServiceImgUrl(service.id);
    },
    selectItem(serviceId) {
      //binding based on name -> name should equal with the id
      this.selectedItem = serviceId;
      this.$emit("selected-service-changed", { serviceId: serviceId });
    },
    isSelected(service) {
      return this.selectedItem == service.id;
    }
  },
  created() {
    this.selectedItem = localStorage.serviceId;
    this.$emit("selected-service-changed", { serviceId: localStorage.serviceId });
  }
};
</script>
