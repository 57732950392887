<template>
  <div>
    <div v-if="!$vuetify.breakpoint.mobile || $vuetify.breakpoint.sm">
    <v-row class="vpd-account-summary__data" justify="space-between">
      <v-col class="vpd-account-summary__amounts pt-7">
        <span class="subtitle-1 pr-1">
          {{ title }}
        </span>
        <span
          v-for="(sum, index) in summaries"
          :key="sum.currency"
          class="subtitle-1"
        >
          <span v-if="index > 0 && index <= summaries.length - 1"> és </span>
          <span class="font-weight-bold">{{
            getNiceValue(sum.amount, sum.currency)
          }}</span>
        </span>
        <span v-if="summaries.length == 0"  class="font-weight-bold"> -</span>
      </v-col>
      <v-col class="vpd-account-summary__refresh">
        <a class="vpd-account-summary__refresh_button" @click="$emit('manualRefresh')">Adatok frissítése</a>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="vpd-account-summary__last_refresh">
        <span>Utolsó frissítés időpontja: </span><span>{{this.lastSynchronizationDate}}</span>
      </v-col>
    </v-row>
    </div>

    <div v-if="$vuetify.breakpoint.xs">
    <v-row class="vpd-account-summary__data" justify="space-between">
      <v-col class="vpd-account-summary__refresh">
        <a class="vpd-account-summary__refresh_button" @click="$emit('manualRefresh')">Adatok frissítése</a>
      </v-col>
      <v-col class="vpd-account-summary__last_refresh">
        <span>Utolsó frissítés időpontja: </span><span>{{this.lastSynchronizationDate}}</span>
      </v-col>
    </v-row>
    <div>
      <div class="vpd-account-summary__amounts">
        <span class="subtitle-1">
          {{ title }}
        </span>
        <span
          v-for="(sum, index) in summaries"
          :key="sum.currency"
          class="subtitle-1 pt-1"
        >
          <span v-if="summaries.length == 1 || index == summaries.length - 1" class="font-weight-bold">
            {{getNiceValue(sum.amount, sum.currency)}}
          </span>
          <span v-if="index >= 0 && index < summaries.length - 1" class="font-weight-bold">
            {{getNiceValue(sum.amount, sum.currency)}} <span class="font-weight-normal">és</span>
          </span>
        </span>
        <span v-if="summaries.length == 0"  class="font-weight-bold"> -</span>
      </div>
      </div>
    </div>
  </div>
</template>


<script>
import "./_vpd-account-summary.scss";
import { getFormattedAmountValue } from "../../modules/helpers";

export default {
  name: "vpd-account-summary",
  props: {
    summaries: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: false,
    },
    lastSynchronizationDate: {
      type: String,
      required: true,
    }
  },
  methods: {
    getNiceValue(value, currency) {
      return getFormattedAmountValue(value, currency);
    },
  },
};
</script>
