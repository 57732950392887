import config from "./config";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vsm from "./modules/vsm/src/index";
import Amplify from "aws-amplify";
// import Auth from "aws-amplify";
import awsconfig from "./aws-exports";
import AsyncComputed from "vue-async-computed";
import { contextualRedirectUri } from "./modules/helpers";
// import { MqttOverWSProvider } from "@aws-amplify/pubsub/lib/Providers";
import Toasted from "vue-toasted";
import VueCookie from "vue-cookie";
import VueGtag from "vue-gtag";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./scss/styles.scss";
import VueFormulate from '@braid/vue-formulate';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Notifications from 'vue-notification'

Vue.config.productionTip = false;
Vue.use(AsyncComputed);
Vue.use(vsm.plugin, store);

Vue.use(VueToast, { position: "bottom-right", duration: "6000", type: "success"});
Vue.use(Toasted, { theme: "bubble" });
Vue.use(Notifications);
Vue.use(VueCookie);

const statisticalCookiesEnabled = Vue.cookie.get("statistical") ? true : false;

Vue.use(VueGtag, {
  config: { id: "G-QTKHWT5X2J" },
  bootstrap: statisticalCookiesEnabled,
}, router);


if (!statisticalCookiesEnabled) {
  Vue.cookie.delete("_ga_QTKHWT5X2J", {domain: "." + window.location.hostname});
}

awsconfig.oauth.redirectSignIn = contextualRedirectUri(
  awsconfig.oauth.redirectSignIn
);
awsconfig.oauth.redirectSignOut = contextualRedirectUri(
  awsconfig.oauth.redirectSignOut
);

Amplify.configure(awsconfig);
Vue.use(Amplify);
Vue.use(VueFormulate);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App, { props: { config } }),
}).$mount("#app");
