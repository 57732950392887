<template>
  <v-container
    class="vpd-content"
    v-if="!this.showNotification"
    key="reset-pass-state"
    fluid
  >
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-resetpass-panel @submit="submitReset" ref="resetpassPanel">
    </vpd-resetpass-panel>
  </v-container>
  <v-container v-else key="reset-pass-state">
    <vpd-notification-panel
      :titleText="notificationTitle"
      :bodyText="notificationText"
      :buttonRoute="buttonRoute"
      :buttonText="buttonText"
    />
  </v-container>
</template>

<script>
import VpdResetpassPanel from "../components/auth/vpd-resetpass-panel/vpd-resetpass-panel";
import VpdNotificationPanel from "../components/vpd-notification-panel/vpd-notification-panel";
import { forgotPassword } from "../modules/auth";
import { camelCase } from "change-case";

const notificationTitle = "Elfelejtett jelszó";
const notificationText =
  "Elküldtük a visszaállító linket a megadott email címre. Kövesse az ottani utasításokat!";
const buttonText = "Főoldal";
const buttonRoute = "/";

// eslint-disable-next-line no-unused-vars
const errorMessages = {
  InvalidParameterException: "Kiszolgáló hiba!",
  UserLambdaValidationException: "Kiszolgáló hiba!",
};

export default {
  name: "reset-pass",
  props: ["config"],
  components: {
    VpdResetpassPanel,
    VpdNotificationPanel,
  },
  data: () => ({
    overlay: false,
    showNotification: false,
    notificationTitle: "",
    notificationText: "",
    buttonRoute: "",
    buttonText: "",
  }),

  methods: {
    async submitReset(email) {
      const panel = this.$refs.resetpassPanel;
      this.overlay = true;
      const status = await forgotPassword(email);
      this.overlay = false;
      if (status.code === "success") {
        this.showNotification = true;
        return true;
      }
      const handler = `${camelCase(status.code)}Handler`;
      if (typeof this[handler] === "function") {
        this[handler](panel, email);
      } else {
        panel.showAlert(`Kiszolgáló hiba! ${status.message}`, "error");
      }
    },
  },
  created() {
    this.notificationTitle = notificationTitle;
    this.notificationText = notificationText;
    this.buttonRoute = buttonRoute;
    this.buttonText = buttonText;
  },
};
</script>
