import Images from "@/assets/images.js";
const config = {
  aspspProviders: [
  {
      id: "otp",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/otp/tokens/",
        redirect: "/banks/otp/redirecturl",
      },
      meta: {
        description: "OTP Bank",
        class: "bank-logo--otp",
        image: Images.logos.otp.original,
      },
  },
  {
      id: "raiffeisen",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/raiffeisen/tokens/",
        redirect: "/banks/raiffeisen/redirecturl",
      },
      meta: {
        description: "Raiffeisen Bank",
        class: "bank-logo--raiffeisen",
        image: Images.logos.raiffeisen.original,
      },
  },
  {
      id: "kh",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/kh/tokens/",
        redirect: "/banks/kh/redirecturl",
      },
      meta: {
        description: "K&H Bank",
        class: "bank-logo--kh",
        image: Images.logos.kh.original,
      },
  },
  {
      id: "erste",
      label: "Erste George ügyfelek",
      auth: {
        availableAccounts: "/banks/erste/tokens/",
        redirect: "/banks/erste/redirecturl",
      },
      meta: {
        description: "Erste George ügyfelek",
        class: "bank-logo--erste",
        image: Images.logos.erste.original,
      },
  },
  {
    id: "erstecorporate",
    label: "Vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/erstecorporate/tokens/",
        redirect: "/banks/erstecorporate/redirecturl",
      },
      meta: {
        description: "Erste Bank Vállalati",
        class: "bank-logo--erste",
        image: Images.logos.erste.original,
      },
  },
  {
      id: "cib",
      label: "Lakossági és KKV ügyfelek",
      auth: {
        availableAccounts: "/banks/cib/tokens/",
        redirect: "/banks/cib/redirecturl",
      },
      meta: {
        description: "Cib Bank",
        class: "bank-logo--cib",
        image: Images.logos.cib.original,
      },
  },
  {
      id: "cibvallalati",
      label: "Nagyvállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/cibvallalati/tokens/",
        redirect: "/banks/cibvallalati/redirecturl",
      },
      meta: {
        description: "Cib Bank Vállalati",
        class: "bank-logo--cib",
        image: Images.logos.cib.original,
      },
	},
  {
      id: "unicredit",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/unicredit/tokens/",
        redirect: "/banks/unicredit/redirecturl",
      },
      meta: {
        description: "UniCredit Bank",
        class: "bank-logo--unicredit",
        image: Images.logos.unicredit.original,
      },
  },
  {
    id: "wise",
    label: "Lakossági és vállalati ügyfelek",
    auth: {
      availableAccounts: "/banks/wise/tokens/",
      redirect: "banks/wise/redirecturl",
    },
    meta: {
      class: "bank-logo--wise",
      description: "Wise",
      image: Images.logos.wise.original,
    },
  },
    {
      id: "granit",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/granit/tokens/",
        redirect: "banks/granit/redirecturl",
      },
      meta: {
        class: "bank-logo--granit",
        description: "Gránit Bank",
        image: Images.logos.granit.original,
      },
    },
    {
      id: "budapest",
      label: "korábban Budapest Bank Lakossági ügyfelek (BB Netbank)",
      auth: {
        availableAccounts: "/banks/budapest/tokens/",
        redirect: "/banks/budapest/redirecturl",
      },
      meta: {
        class: "bank-logo--bb",
        description: "MBH Bank",
        image: Images.logos.mkb.original,
      },
    },
    {
      id: "mkb",
      label: "korábban MKB Lakossági ügyfelek (MBK Netbankár)",
      auth: {
        availableAccounts: "/banks/mkb/tokens/",
        redirect: "banks/mkb/redirecturl",
      },
      meta: {
        class: "bank-logo--mkb",
        description: "MBH Bank",
        image: Images.logos.mkb.original,
      },
    },
    {
      id: "takarek",
      label: "korábban Takarék ügyfelek (Takarék Netbank)",
      auth: {
        availableAccounts: "/banks/takarek/tokens/",
        redirect: "banks/takarek/redirecturl",
      },
      meta: {
        class: "bank-logo--takarek",
        description: "MBH Bank",
        image: Images.logos.mkb.original,
      },
    },
    {
      id: "mkbvallalati",
      label: "Vállalati Netbank (korábban BB és MKB)",
      auth: {
        availableAccounts: "/banks/mkb/tokens/",
        redirect: "banks/mkb/redirecturl",
      },
      meta: {
        class: "bank-logo--mkb",
        description: "MBH Bank",
        image: Images.logos.mkb.original,
      },
    },
    {
      id: "magnet",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/magnet/tokens/",
        redirect: "/banks/magnet/redirecturl",
      },
      meta: {
        description: "Magnet Bank",
        class: "bank-logo--magnet",
        image: Images.logos.magnet.original,
      },
  },
    {
      id: "revolut",
      label: "Lakossági és vállalati ügyfelek",
      auth: {
        availableAccounts: "/banks/revolut/tokens/",
        redirect: "/banks/revolut/redirecturl",
      },
      meta: {
        description: "Revolut Bank",
        class: "bank-logo--revolut",
        image: Images.logos.revolut.original,
      },
    },
  ],
};

export default config;
