import axiosService from "@/modules/axios-service";

class PartnerAttachmentService {
  path = "/services";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async validateEntityId(requestBody) {
    const path = this.path + "/connectionrequestvalidation";
    return await axiosService.post(path, requestBody);
  }

}

export default new PartnerAttachmentService();