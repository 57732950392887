<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="15"
      y="33.5176"
      width="26.1889"
      height="2"
      rx="1"
      transform="rotate(-45 15 33.5176)"
      :fill="color"
    />
    <rect
      x="16.4814"
      y="15.002"
      width="26.1889"
      height="2"
      rx="1"
      transform="rotate(45 16.4814 15.002)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "vpd-icon-x",
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
  },
};
</script>
