import { Auth } from "aws-amplify";
import awsconfig from "./../aws-exports";

class SocketioService {
  socket;
  constructor() {}

  async setupSocketConnection() {
    this.socket = null;
    let access_token = null;
    await Auth.currentSession()
      .then((session) => {
        access_token = session.getAccessToken().getJwtToken();
        this.overlay = false;

        this.socket = new WebSocket(
          `${awsconfig.API.endpoints[1].endpoint}/?access_token=${access_token}`
        );

        this.socket.onopen = function (event) {
        };
      })
      .catch(() => {
        this.overlay = false;
      });
  }

  getSocket() {
    return this.socket;
  }
}

export default new SocketioService();
