<template>
  <div>
    <header class="vpd-toolbar">
      <nav>
        <ul
          class="vpd-navigation d-flex d-row justify-space-between align-center"
          :class="isOpten && scrollPosition > 50 ? 'opten-background-color' : isOpten ? 'opten-background' : ''"
        >
          <li>
            <v-img v-if="!isOpten"
              @click="routeToHome"
              class="vpd-toolbar__logo"
              :src="require('../../assets/logo-transparent.svg')"
              height="51"
              contain
              alt="Bankszamlakivonat.hu"
            />
          </li>

          <li class="d-none d-lg-flex">
            <div class="d-flex align-center">
              <router-link
                v-if="userState === 1"
                key="user-logged-in-state"
                :to="{ name: 'userprofile' }"
                class="vpd-toolbar__link vpd-navigation-link"
                >Profil</router-link
              >
              <router-link
                v-else
                key="user-logged-in-state-2"
                :to="{ name: 'registration' }"
                class="vpd-toolbar__link vpd-navigation-link"
              >
                Regisztráció</router-link
              >
              <router-link v-slot="{ navigate }" :to="navigation.link">
                <v-btn
                  class="vpd-transparent-background"
                  outlined
                  @click="navigate"
                  >{{ navigation.label }}</v-btn
                >
              </router-link>
            </div>
          </li>
          <li class="d-flex d-lg-none">
            <v-btn icon @click="drawer = !drawer">
              <vpd-icon-menu></vpd-icon-menu>
            </v-btn>
          </li>
        </ul>
      </nav>
    </header>
    <v-navigation-drawer
      v-model="drawer"
      class="navigation-drawer p-0"
      fixed
      temporary
      right
    >
      <span class="d-flex justify-end">
        <v-btn icon @click="drawer = !drawer">
          <vpd-icon-x></vpd-icon-x>
        </v-btn>
      </span>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="userState === 1">
            <v-list-item-title>
              <router-link
                key="user-logged-in-state"
                :to="{ name: 'userprofile' }"
                >Profil</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item v-else>
            <v-list-item-title>
              <router-link
                key="user-logged-in-state"
                :to="{ name: 'registration' }"
                >Regisztráció</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link :to="navigation.link">{{
                navigation.label
              }}</router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import "./_vpd-toolbar.scss";
import VpdIconX from "@/components/icons/VpdIconX.vue";
import VpdIconMenu from "@/components/icons/VpdIconMenu.vue";

export default {
  components: { VpdIconX, VpdIconMenu },
  props: {
    loginActions: {
      type: Array,
      required: true,
    },
    isOpten: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      userState: 0,
      drawer: false,
      scrollPosition: null,
    };
  },
  computed: {
    navigation() {
      return {
        link: { name: this.loginActions[this.userState].name },
        label: this.loginActions[this.userState].label,
      };
    },
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    routeToHome() {
      return this.$router.push("/").catch(err => {});
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "general/userState") {
        this.userState = +(mutation.payload === "signedIn");
      }
    });
  },
};
</script>
