import axiosService from "@/modules/axios-service";

class BankRequirementsService {
  path = "/banks";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async get(bankId, requestBody) {
    const path = this.path +
    "/" +
    bankId +
    "/requirements"
    return await axiosService.get(path, requestBody);
  }
}

export default new BankRequirementsService();