<template>
  <v-container class="vpd-sub-content" fluid>
    <v-dialog v-model="showBanklistDialog" content-class="v-dialog--custom">
      <vpd-banklist-panel v-on:close-dialog="closeDialog" :config="config" :active.sync="dialog" />
    </v-dialog>
    <vpd-confirm-dialog
      headline="Szolgáltatást csak bankszámlához lehet hozzáadni"
      subtitle="Csatlakoztassa bankszámláját, és adja hozzá a szolgáltatásokat! Elindítja a számla hozzáadást most?"
      :dialog="showDialog"
      event="add-new-account"
      v-on:add-new-account="showBanklist()"
      v-on:close-modal="showDialog = false"
    ></vpd-confirm-dialog>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-summary v-if="!underConstruction"
      title="Számlatörténetét megoszthatja az alábbi partnereinkkel:"
      subtitle="Igénybe vett szolgáltatások száma: "
      :value="servicesLength"
    />
    <v-divider />
    <p v-if="underConstruction" class="p-under-construction mt-3">Hamarosan érkező funkció!</p>
    <div v-if="!$vuetify.breakpoint.xs && underConstruction" class="img-container-under-construction">
      <img :src="getUnderConstructionImgUrl('big')" />
    </div>
    <div v-if="$vuetify.breakpoint.xs && underConstruction" class="img-container-under-construction">
      <img class="img-under-construction-mobile" :src="getUnderConstructionImgUrl('small')" />
    </div>
    <vpd-service-panel v-if="services[0] && !underConstruction" :services="services" :accounts="accounts">
    </vpd-service-panel>
  </v-container>
</template>

<script>
import VpdSummary from "@/components/vpd-summary/vpd-summary";
import VpdServicePanel from "@/components/vpd-service-panel/vpd-service-panel";
import VpdBanklistPanel from "@/components/vpd-banklist-panel/vpd-banklist-panel";
import accountsService from "@/services/accounts-service";
import servicesService from "@/services/services-service";
import VpdConfirmDialog from "@/components/vpd-confirm-dialog/vpd-confirm-dialog";
import { getUnderConstructionImgUrl } from "@/modules/helpers";

export default {
  name: "services",
  props: ["config"],
  data() {
    return {
      showDialog: false,
      showBanklistDialog: false,
      accounts: [],
      loadingTransactions: false,
      loadingAccounts: false,
      summaries: [],
      services: [],
      overlay: false,
      dialog: {},
      values: {},
      underConstruction: false,
    };
  },
  computed: {
    servicesLength() {
      return this.services.filter(
        (service) => service.numberOfConnectedAccounts > 0
      ).length;
    },
  },
  components: {
    VpdSummary,
    VpdServicePanel,
    VpdConfirmDialog,
    VpdBanklistPanel,
  },
  methods: {
    showBanklist() {
      this.showDialog = false;
      this.showBanklistDialog = true;
    },
    countServices() {
      this.services.forEach((service) => {
        if (service.numberOfConnectedAccounts > 0) {
          this.servicesLength += 1;
        }
      });
    },
    getUnderConstructionImgUrl(size) {
      return getUnderConstructionImgUrl(size);
    },
    loadAccountsData: function (data) {
      this.accounts = data.items;
      this.summaries = data.summaries;
      this.$store.commit("setAccountsCount", this.accounts.length);
    },
    setLoading: function (value) {
      this.overlay = value;
      this.loadingAccounts = value;
      this.loadingTransactions = value;
    },
    closeDialog: function(){
            this.showBanklistDialog = false;
    },
  },
  async created() {
    // accounts loading
    this.setLoading(true);
    const accountsData = await accountsService.fetchAccounts();
    if (accountsData) this.loadAccountsData(accountsData);

    // services loading
    this.services = await servicesService.fetchServices();
    // Filter out Leanpay
    this.services = this.services.filter(service => service.id !== "3cb23ecf-a2c8-4193-b441-0f1945d9ccb1")

    this.setLoading(false);

    if (this.$store.state.account.count <= 0) {
      this.showDialog = true;
    }
  },
};
</script>
