import Vue from "vue";
import Vuex from "vuex";
import general from "./modules/general/";
import registration from "./modules/registration";
import vsm from "./modules/vsm";
import createPersistedState from "vuex-persistedstate";
import { parse, stringify } from "flatted";

const persistedLocal = createPersistedState({
  storage: window.localStorage,
  getState: (key, storage) => {
    const item = storage.getItem(key);
    return item ? parse(item) : null;
  },
  setState: (key, state, storage) => {
    storage.setItem(key, stringify(state));
  },
  reducer: (state) => ({
    general: state.general,
    vsm: state.vsm,
  }),
});

Vue.use(Vuex);

let plugins = [persistedLocal];

export default new Vuex.Store({
  state: {
    account: {
      count: 0,
    },
  },
  modules: {
    general,
    registration,
    vsm,
  },
  mutations: {
    setAccountsCount(state, count) {
      state.account.count = count;
    },
  },
  plugins: plugins,
});
