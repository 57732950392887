const machine = {
  initial: "showRegistration", // default state, may be override by init
  strict: true,
  states: {
    showRegistration: {
      on: {
        REGISTRATION_CLICKED: {
          showConfirmation: {
            actions: ["SHOW_CONFIRMATION"],
          },
        },
      },
    },

    showConfirmation: {
      on: {
        CONFIRMATION_CLCKED: {
          showRegistration: {
            actions: ["SHOW_REGISTRATION"],
          },
        },
      },
    },
  },
};

export default machine;
