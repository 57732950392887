<template>
  <v-card class="vpd-confirm-newpass-panel">
    <v-card-title class="headline text-center"
      >Új jelszó beállítása</v-card-title
    >
    <v-alert
      v-model="alert"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertMessage }}</v-alert
    >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-col align="center" class="subtitle-1">
          Adja meg az új jelszavát!
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="4">
          <v-text-field
            label="Jelszó"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :rules="passwordRules"
            outlined
            name="password"
            v-model="password"
            ref="pass"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="4">
          <v-text-field
            label="Jelszó mégegyszer"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :rules="matchRules"
            outlined
            ref="passcheck"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <v-btn
            class="primary fix-width"
            type="submit"
            @click.prevent="confirm"
            >Új jelszó beállítása</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import "./_vpd-confirm-newpass-panel.scss";
export default {
  name: "vpd-confirm-newpass-panel",
  computed: {
    matchRules() {
      const context = this;
      return [
        function (v) {
          return (v && v === context.password) || "A jelszavak nem egyeznek";
        },
      ];
    },
  },
  data() {
    return {
      alert: false,
      alertType: "error",
      alertMessage: "",
      userEmail: "",
      userCode: "",
      valid: false,
      confirmed: false,
      showPassword: false,
      emailRules: [
        (v) => !!v || "Az email cím kötelező",
        (v) => /.+@.+/.test(v) || "Érvénytelen email",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Hiányzó jelszó",
        (v) => (v && v.length >= 8) || "A jelszó min. 8 karakter",
      ],
      authCodeRules: [(v) => !!v || "A hitelesítő kód megadása kötelező"],
    };
  },
  methods: {
    showAlert(text, type) {
      this.alert = true;
      this.alertType = type;
      this.alertMessage = text;
    },
    confirm() {
      //TODO event handling, remove this.$refs
      if (this.$refs.form.validate()) {
        this.confirmed = true;
        this.$emit("submit", this.password);
      }
    },
  },
};
</script>
