<template>
  <v-container class="vpd-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-notification-panel
      :alertText="alertMessage"
      :alertType="alertType"
      :buttonRoute="buttonRoute"
      :buttonText="buttonText"
    />
  </v-container>
</template>

<script>
import VpdNotificationPanel from "../components/vpd-notification-panel/vpd-notification-panel";
import activationService from "@/services/activation-service";

const buttonText = "Vissza a főoldalra";
const buttonRoute = "/";

export default {
  name: "registration",
  props: ["config"],
  components: { VpdNotificationPanel },
  data: () => ({
    overlay: false,
    alertType: "",
    alertMessage: "",
    buttonText: "",
    buttonRoute: "",
  }),
  methods: {
    routeToHome() {
      return this.$router.push("/");
    },
    async activate() {
      this.overlay = true;
      const result = await activationService.activate(this.$route.query);
      this.alertType = result.alertType;
      this.alertMessage = result.alertMessage;

      this.overlay = false;
    },
  },
  created() {
    this.activate();
    this.buttonRoute = buttonRoute;
    this.buttonText = buttonText;
  },
};
</script>
