<template>
  <div v-html="markdownToHtml"></div>
</template>
<script>
import { marked } from "marked"

export default {
  name: 'vpd-markdown',
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  computed: {
    markdownToHtml(){
      return marked(this.cleanMarkDown(this.source));
    }
  },
  methods: {
    cleanMarkDown(markDownText) {
      return markDownText
          .replaceAll("          ", "")
          .replaceAll("# ", "#### ");
    },
  }
}
</script>