import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import config from "../config";
import { Auth, Hub } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import { signOut } from "../modules/auth";
import VpdEventBus from "../modules/vpd-event-bus";
import Accounts from "../views/Accounts.vue";
import Services from "../views/Services.vue";
import AddServices from "../views/AddServices.vue";
import AddServicesSummary from "../views/AddServicesSummary.vue";
import ServicesTermsAndConditions from "../views/ServicesTermsAndConditions.vue";
import Login from "../views/Login.vue";
import ConfirmNewPass from "../views/ConfirmNewPass.vue";
import Registration from "../views/Registration.vue";
import Activation from "../views/Activation.vue";
import UserProfile from "../views/UserProfile.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ResetPass from "../views/ResetPass.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Google from "../views/Google.vue";
import DeleteUser from "../views/DeleteUser.vue";
import Banks from "../views/Banks.vue";
import Consents from "../views/Consents.vue";
import Callback from "../views/Callback.vue";
import Page404 from "../views/404.vue";
import CookiePolicy from "../views/CookiePolicy.vue";
import CashbookSso from "../views/CashbookSso.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    name: "home",
    props: { config },
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "defaultPath ",
        redirect: "accounts",
        props: { config },
      },
      {
        path: "accounts",
        name: "accounts",
        component: Accounts,
        props: { config },
        meta: { requiresAuth: true },
      },
      {
        path: "services",
        name: "services",
        component: Services,
        props: { config },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/add-services",
    name: "add-services",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AddServices,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/oauth/callback/:bank",
    name: "oauth-handler",
    component: Callback,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      to.meta.props = {
        to: to,
      };
      next();
    },
  },
  {
    path: "/add-services-summary",
    name: "add-services-summary",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AddServicesSummary,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/services-terms-and-conditions",
    name: "services-terms-and-conditions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicesTermsAndConditions,
    props: { config },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: { config },
    meta: { authEntry: true },
  },
  {
    path: "/logout",
    name: "logout",
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      await signOut();
      next({ name: "home" });
    },
  },
  {
    path: "/reset-pass",
    name: "resetPass",
    component: ResetPass,
    props: { config },
  },
  {
    //TODO remove after e-mail renamed
    path: "/resetpass",
    name: "confirmNewpass",
    component: ConfirmNewPass,
    props: { config },
  },
  {
    path: "/confirm-newpass",
    name: "confirmNewpass",
    component: ConfirmNewPass,
    props: { config },
  },
  {
    path: "/registration",
    name: "registration",
    component: Registration,
    props: { config },
    meta: { authEntry: true },
  },
  {
    path: "/activation",
    name: "activation",
    component: Activation,
    props: { config },
    meta: { authEntry: true },
  },
  {
    path: "/userprofile",
    name: "userprofile",
    component: UserProfile,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    props: { config },
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: CookiePolicy,
    props: { config },
  },
  {
    path: "/ggl",
    name: "google",
    component: Google,
    props: { config },
  },
  {
    path: "/deleteuser",
    name: "deleteuser",
    component: DeleteUser,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/banks",
    name: "banks",
    component: Banks,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/banks/:id",
    name: "invidual-banks",
    component: Consents,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/sso/succeeded",
    name: "cashbook-sso",
    component: CashbookSso,
    props: { config },
    meta: { requiresAuth: true },
  },
  {
    path: "/sso/failed",
    name: "cashbook-sso",
    component: CashbookSso,
    props: { config },
    meta: { requiresAuth: true },
  },
  /*{
      path: "/ws",
      name: "ws",
      component: () => import("../views/WsTest.vue"),
      props: { config },
      meta: { requiresAuth: true },
    },*/
  {
    path: "*",
    name: "404",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'auto' });
}
});

const redirects = {
  signedOut: () => {
    router.push({ path: "login" });
  },
  signUp: () => {
    router.push({ path: "registration" });
  },
  confirmSignUp: () => {
    router.push({ name: "confirm" });
  },
  signIn: () => {
    router.push({ name: "login" });
  },
  signedIn: () => {
    const path = router.app.$route.query.redirect || "/";
    router.push({ path }).catch(()=>{});
  },
  forgotPassword: () => {
    router.push({ name: "confirmNewpass" });
  },
  signIn_failure: () => {
  },
  redirect_federated: (provider) => {
    let response, user;
    Auth.federatedSignIn({ provider }, response, user)
      .then()
      .catch();
  },
};

AmplifyEventBus.$on("authState", async (state) => {
  if (typeof redirects[state] === "function") {
    router.app.$store.dispatch("general/userState", state);
    redirects[state]();
  }
});

VpdEventBus.$on("redirect", async (data) => {
  const entry = `redirect_${data.name}`;
  if (typeof redirects[entry] === "function") {
    redirects[entry](data.payload);
  }
});

Hub.listen("auth", (data) => {
  const state = data.payload.event;

  switch (state) {
    case "signIn":
      AmplifyEventBus.$emit("authState", "signedIn");
      break;
    case "signIn_failure":
      break;
    case "tokenRefresh_failure":
      break;
    default:
      break;
  }
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user && user.signInUserSession) {
          router.app.$store.dispatch("general/userState", "signedIn");
        }
        next();
      })
      .catch((e) => {
        next({
          name: "login",
          query: {
            redirect: to.fullPath,
          },
        });
      });
  }
  next();
});

export default router;
