<template>
  <v-container class="vpd-content" fluid>
    <v-card class="vpd-banklist-panel">
      <v-card-title class="headline">Elérhető bankok:</v-card-title>
      <v-row justify="space-around">
        <v-col
          v-for="provider in config.aspspProviders"
          :key="provider.id"
          class="d-flex child-flex"
          lg="3"
          md="4"
          sm="6"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card flat tile class="d-flex" :elevation="hover ? 12 : 0">
              <v-img
                :src="provider.meta.image"
                contain
                @click="select(provider.id)"
                class="bank-logo"
                :class="provider.meta.class"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      dialog: false,
      bank: this.$route.query.bank,
    };
  },
  props: ["config"],
  methods: {
    select(bank) {
      this.$router.push({ path: `banks/${bank}` });
      this.bank = this.$route.query.bank;
    },
  },
  created() {},
};
</script>
