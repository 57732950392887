import { render, staticRenderFns } from "./vpd-cookie-banner-message.vue?vue&type=template&id=44cae12b&"
import script from "./vpd-cookie-banner-message.vue?vue&type=script&lang=js&"
export * from "./vpd-cookie-banner-message.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports