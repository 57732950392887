<template>
  <div class="py-0">
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card class="vpd-panel vpd-service-summary">
      <vpd-card-title title="Összegzés" has-back-button></vpd-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <span class="subtitle-1">Kiválasztott szolgáltatás</span>
        <vpd-service-overview-panel :service="service" :codeIsValidated="codeIsValidated" @setcodeIsValidated="setcodeIsValidated" @setEntityId="setEntityId" v-on:inputChange="handleEmailChange" :emailAddress="emailAddress" :khEmailEnding="khEmailEnding">
        </vpd-service-overview-panel>

        <v-container class="vpd-service-panel__choosen-accounts">
          <span class="subtitle-1">Kiválasztott bankszámlák:</span>
          <div v-for="account in accounts" :key="account.id">
            <vpd-account-simple-panel
              :account="account"
            ></vpd-account-simple-panel>
          </div>
          <span style="font-weight: bold;" v-if="service.id == 'ffb054af-185d-425c-a838-832d633f59e0'">A megosztás egyszeri alkalomra szól, ezt követően a bank nem fér hozzá az adataihoz!<br></span>
          <span style="font-weight: bold;" v-if="service.id == '429c30f3-e5d9-4c1c-a44f-53555d313abd'">A megosztás egyszeri alkalomra szól, ezt követően a bank nem fér hozzá az adataihoz!<br></span>
          <span style="font-weight: bold;" v-if="service.id == '86cb974c-8579-4d9a-999a-f7b5cf0bb2ef'">A megosztás egyszeri alkalomra szól, ezt követően az OPTEN Kft. (Do Your Carbon) nem fér hozzá az adataihoz!<br></span>
          <span style="font-weight: bold;" v-if="service.id == '0c470a51-b6f2-4683-8c54-5cc1ca08b881'">A megosztás egyszeri alkalomra szól, ezt követően az OPTEN Kft. nem fér hozzá az adataihoz!<br></span>
          <span style="font-weight: bold;" v-if="service.id == '0906e55f-9fab-44e9-a8f7-80fbf05420be'">Az  adatok megosztása a szolgáltatás érdekében folyamatos, a megosztás leállításáig érvényes. <br></span>
                    <span style="font-weight: bold;" v-if="service.id == '0f41f27e-e18f-4dc1-991a-de8c38bb9e06'">Az  adatok megosztása a szolgáltatás érdekében folyamatos, a megosztás leállításáig érvényes. <br></span>
          <span style="font-weight: bold;" v-if="service.id == 'f8756a3f-badf-4bc2-9681-5a8ab5215195'">Az  adatok megosztása a szolgáltatás érdekében folyamatos, a megosztás leállításáig érvényes. <br></span>
          <span class="subtitle-1">Az alábbi számlainformációk kerülnek továbbításra:</span>
          <div>
            <ul id="data-share-list">
              <li v-for="item of SHARED_DATA" :key="item">{{ item }}</li>
            </ul>
          </div>
        </v-container>
        <div class="pa-0 mt-8">
          <v-col cols="12" class="pa-0 col-dense d-flex">
            <v-checkbox
              v-model="servicesTermsAccepted"
              class="my-0"
              :rules="acceptRule"
            >
              <template v-slot:label>
                <div class="vpd-service-summary-panel__checkbox">
                  Igen, hozzájárulok a megosztáshoz.
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </div>
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-btn
              :disabled='!codeIsValidated || !servicesTermsAccepted'
              class="primary d-none d-lg-flex"
              type="submit"
              @click.prevent="submit()"
              >Megosztás indítása
            </v-btn>
            <v-btn
              :disabled='!codeIsValidated || !servicesTermsAccepted'
              class="primary fix-width d-sm-flex d-lg-none"
              type="submit"
              @click.prevent="submit()"
              >Tovább
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <vpd-alert-dialog
      headline="Számlatörténet megosztása sikeres!"
      message="Az adatok megosztásra kerültek a kiválasztott szolgáltatóval."
      :dialog="dialog"
      event="click-accept"
      v-on:click-accept="routeToHome"
      v-on:close-modal="dialog = false"
    ></vpd-alert-dialog>
    <vpd-alert-dialog
      headline="Számlatörténet megosztása sikeres!"
      message="Örömmel tájékoztatjuk, hogy a Do Your Carbon platformon indított megrendelésének folyamata lezárult. Megvásárolt termékét hamarosan elküldjük a megrendeléskor megadott e-mail címre, valamint a belépett felhasználói fiókjából is letöltheti."
      :dialog="optenDialog"
      :buttonText="'Vissza a DYC profilra'"
      event="opten-click-accept"
      v-on:opten-click-accept="routeToDyc"
      v-on:close-modal="optenDialog = false"
    ></vpd-alert-dialog>
  </div>
</template>
<script>
import VpdAccountSimplePanel from "@/components/vpd-accounts/vpd-account-simple-panel/vpd-account-simple-panel";
import VpdAlertDialog from "@/components/vpd-alert-dialog/vpd-alert-dialog";
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
import VpdServiceOverviewPanel from "@/components/vpd-services/vpd-service-overview-panel/vpd-service-overview-panel";
import servicesService from "@/services/services-service";

export default {
  name: "vpd-service-summary-panel",
  props: ["config"],
  components: {
    VpdAccountSimplePanel,
    VpdAlertDialog,
    VpdCardTitle,
    VpdServiceOverviewPanel,
  },
  data() {
    return {
      overlay: true,
      accounts: [],
      service: [],
      servicesTermsAccepted: false,
      acceptRule: [(v) => v === true || "A szerződés elfogadása kötelező"],
      valid: false,
      dialog: false,
      codeIsValidated: false,
      entityId: "",
      emailAddress: "",
      khEmailEnding: "@kh.hu",
      optenDialog: false,
      isOpten: false,
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        const body = {}
        const accountList = [];

        this.accounts.forEach((account) => {
          accountList.push(account.id);
        });

        body["accountList"] = accountList;
        body["entityId"] = this.entityId;
        body["emailAddress"] = this.emailAddress;

        if (this.isOpten) {
          this.optenDialog = await servicesService.addServiceToAccounts(
            "/" + this.service.id + "/accounts",
            body
          );
        } else {
          this.dialog = await servicesService.addServiceToAccounts(
            "/" + this.service.id + "/accounts",
            body
          );
        }


        this.loadingAccounts = false;
      }
    },
    routeToHome() {
      return this.$router.replace("/accounts");
    },
    routeToDyc() {
      window.location = "https://doyourcarbon.hu/users/profile";
    },
    setcodeIsValidated() {
      this.codeIsValidated = true;
    },
    setEntityId(entityId) {
      this.entityId = entityId;
    },
    handleEmailChange(event) {
      this.emailAddress = event;
      if (this.service["isMultipleEmailNeeded"] && this.emailAddress !== "") {
        this.codeIsValidated = true;
      }

      if (this.service["isMultipleEmailNeeded"] && this.emailAddress === this.khEmailEnding) {
        this.codeIsValidated = false;
      }
    }
  },

  created() {
    this.SHARED_DATA = [
      "Bank neve",
      "Bankszámlaszáma",
      "Tranzakciótörténet",
      "Számlaegyenlege",
      "Az Ön neve",
      "Az Ön e-mail címe",
    ];
    this.service = JSON.parse(localStorage.selectedServiceId);
    this.isOpten = localStorage.isOpten ? JSON.parse(localStorage.isOpten) : false;
    
    if (!this.service["isEntityIdNeeded"] && !this.service["isMultipleEmailNeeded"]) {
      this.codeIsValidated = true;
    }

    this.accounts = JSON.parse(localStorage.selectedAccounts);
    this.overlay = false;
  },
};
</script>
