<template>
  <v-container class="vpd-content" fluid>
    <vpd-service-summary-panel :config="config" />
  </v-container>
</template>
<script>
import VpdServiceSummaryPanel from "@/views/VpdServiceSummaryPanel";

export default {
  name: "service-summary",
  props: ["config"],
  components: {
    VpdServiceSummaryPanel,
  },
};
</script>
