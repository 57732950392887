<template>
  <v-container fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card style="text-align: center" :type="alertType" v-if="showConfirmForm">
      <h1>Biztosan törölni szeretné felhasználóját?</h1>
      <button @click="deleteUser()" style="color: red">
        Felhasználó törlése!
      </button>
    </v-card>
    <v-card :type="alertType" v-if="alert">
      {{ alertMessage }}
    </v-card>
  </v-container>
</template>

<script>
import deleteService from "@/services/delete-service";

export default {
  data: () => ({
    alert: false,
    alertMessage: "",
    overlay: true,
    alertType: "success",
    showConfirmForm: false,
  }),
  methods: {
    wrongPage(errMsg) {
      this.alertMessage = errMsg;
      this.alert = true;
      this.showConfirmForm = false;
      this.alert = true;
    },
  },
  async mounted() {
    if (this.$route.query.code == "" || this.$route.query.code == undefined) {
      this.wrongPage("Hibás oldal!");
    } else {
      this.overlay = true;
      this.showConfirmForm = false;
      const message = deleteService.deleteUser(this.$route.query.code);
      if (message === -1) {
        this.wrongPage("Hibás oldal!");
      } else {
        message.then(res => this.alertMessage = res);
      }
      this.alert = true;
      this.overlay = false;
    }
  },
};
</script>
