import axiosService from "@/modules/axios-service";

class TransactionsService {
  path = "/transactions";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async fetchTransactions(skip = "0", take) {
    return await axiosService.get(this.path, {
      params: {
        skip: skip,
        take: take,
      },
    });
  }
}

export default new TransactionsService();
